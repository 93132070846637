import { getDocs, query, where } from 'firebase/firestore';
import {
  ItemStatRecord,
  getStatRecordKey,
  getEmptyStatRecord,
  DayStatRecord,
} from 'features/stat/stat';
import { Task } from 'models/task';
import loadBatch from './api-utils';
import {
  fsCollection,
  fsGetDoc,
  fsSetDoc,
  fsUpdateDoc,
} from './firestore-collection';

export const saveStatRecord = async (stat: ItemStatRecord) => {
  try {
    await fsSetDoc('stat', stat.key, stat);
    return stat;
  } catch (e) {
    console.error('saveStatRecord', e);
    throw e;
  }
};

export const saveStatRecordDay = async (
  id: string,
  date: string,
  dayRecord: DayStatRecord
) => {
  try {
    const type = id ? 'task' : 'day';
    const key = getStatRecordKey(type, id);
    const doc = await fsGetDoc('stat', key);
    if (doc.exists()) {
      const partial = {};
      partial[`history.${date}`] = dayRecord;
      await fsUpdateDoc('stat', key, partial);
    }
  } catch (e) {
    console.error('saveStatRecordDay', e);
    throw e;
  }
};

export const saveStatRecordProp = async (
  id: string,
  date: string,
  name: string,
  data: any
) => {
  try {
    const type = id ? 'task' : 'day';
    const key = getStatRecordKey(type, id);
    const partial = {};
    partial[`history.${date}.${name}`] = data;
    await fsUpdateDoc('stat', key, partial);
  } catch (e) {
    console.error('saveStatRecordProp', e);
    throw e;
  }
};

export const loadStatRecord = async (
  task?: Task,
  shouldCreate?: boolean
): Promise<ItemStatRecord> => {
  const type = task ? 'task' : 'day';
  try {
    const key = getStatRecordKey(type, task?.id);
    const doc = await fsGetDoc('stat', key);
    if (doc.exists()) {
      return doc.data() as ItemStatRecord;
    }
    const r = getEmptyStatRecord(type, task);
    if (shouldCreate) {
      await saveStatRecord(r);
    }
    return r;
  } catch (e) {
    console.error(`loadStatRecord:${task.id}`, e);
    throw e;
  }
};

const loadTaskStatRecordBatch = async (
  taskIds: string[]
): Promise<ItemStatRecord[]> => {
  const keys = taskIds.map((id) => getStatRecordKey('task', id));
  const q = query(fsCollection('stat'), where('key', 'in', keys));
  const data = await getDocs(q);
  return data.docs.map((d) => d.data() as ItemStatRecord);
};

export const loadTaskStatRecords = async (
  taskIds: string[]
): Promise<ItemStatRecord[]> => {
  try {
    return await loadBatch(taskIds, loadTaskStatRecordBatch);
  } catch (e) {
    console.error('loadTaskStatRecords', e);
    throw e;
  }
};

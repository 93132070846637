import React from 'react';
import type { FC } from 'react';
import { Avatar, Box, Container, Typography } from '@mui/material';

// const PREFIX = 'Testimonials';

// const classes = {
//   root: `${PREFIX}-root`,
//   title: `${PREFIX}-title`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.dark,
//     paddingTop: 128,
//     paddingBottom: 128,
//   },

//   [`& .${classes.title}`]: {
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// }));

interface TestimonialsProps {
  className?: string;
}

const Testimonials: FC<TestimonialsProps> = ({
  className,
  ...rest
}: TestimonialsProps) => (
  <Box
    className={className}
    sx={{
      backgroundColor: 'background.dark',
      pt: 12,
      pb: 12,
    }}
    {...rest}
  >
    <Container maxWidth="md">
      <Typography
        variant="h2"
        align="center"
        color="textPrimary"
        sx={{
          fontWeight: 'regular',
        }}
      >
        &quot;Thriving in the New Economy depends on your ability to perform
        deep work.&quot;
      </Typography>
      <Box mt={6} display="flex" justifyContent="center" alignItems="center">
        <Avatar src="/static/images/avatars/cal-newport.jpg" />
        <Box ml={2}>
          <Typography variant="body1" color="textPrimary">
            Cal Newport
            <Typography color="textSecondary" display="inline" component="span">
              , Deep Work: Rules for Focused Success in a Distracted World
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default Testimonials;

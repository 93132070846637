import { format } from 'date-fns';
import { Task, shortenName } from 'models/task';

export const MINUTES_INC = 5;

export type DayBlockState = 'on' | 'break' | 'stop';

export type DayItemKey = {
  id?: string;
  split?: number;
};

export type DayItem = {
  color?: number;
  state?: DayBlockState;
  label?: string;
} & DayItemKey;

export type DayInterval = {
  start: number;
  end: number;
} & DayItem;

export type ScheduleInterval = {
  flow?: boolean;
} & DayInterval;

export type DayLayer = {
  model: DayModel;
  item?: DayItem;
  selected?: number;
  currentTime?: number;
  schedule?: ScheduleInterval[];
};

export type WorkSession = {
  item?: DayItem;
  started?: number;
  auto?: boolean;
  forced?: boolean;
  pausedSeconds?: number;
  startedSeconds?: number;
};

export type TaskIntentRecord = {
  planned: number;
  done: number;
  time: number;
};

export type TaskIntent = {
  item: DayItem;
  record: TaskIntentRecord;
  history: TaskIntentRecord[];
};

export type DayModel = {
  date: string;
  plan?: DayInterval[];
  tasks?: Task[];
  session?: WorkSession;
  intent?: TaskIntent[];
  ready?: boolean;
};

export type DayGrid = {
  rows: number[];
  cells: number[];
  multi: number;
};

export const emptyLayer = (date: Date): DayLayer => ({ model: emptyDay(date) });

export const emptyDay = (date: Date): DayModel => ({ date: getDateKey(date) });

export const getDateKey = (date?: Date) =>
  format(date ?? new Date(), 'yyyy-MM-dd');

export const getDayItemKey = (item: DayItemKey) => {
  const result = item ? item.id + (item.split ? `-${item.split}` : '') : null;
  return result;
};

export const getDayItem = (
  model: Task,
  state?: DayBlockState,
  includeSplit = true
): DayItem => {
  const item: DayItem = {};
  if (state) {
    item.state = state;
  }
  if (model) {
    item.id = model.id;
    if (model.content) {
      item.label = model.contentShort ?? shortenName(model);
    }
    if (includeSplit && model.split) {
      item.split = model.split;
    }
    if (model.color !== undefined) {
      item.color = model.color;
    }
  }
  return item;
};

export const getDayMinutes = () => {
  const d = new Date();
  return (
    d.getHours() * 60 + Math.floor(d.getMinutes() / MINUTES_INC) * MINUTES_INC
  );
};

export const getDaySeconds = () => {
  const d = new Date();
  return d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();
};

export const normalizeTime = (minutes: number) =>
  Math.ceil((minutes || 0) / MINUTES_INC) * MINUTES_INC;

import React from 'react';
import type { FC } from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { HELP_CENTER } from 'shared/constants';

// const PREFIX = 'FAQS';

// const classes = {
//   root: `${PREFIX}-root`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.dark,
//     paddingTop: theme.spacing(6),
//     paddingBottom: theme.spacing(6),
//     '& dt': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

interface FAQSProps {
  className?: string;
}

const FAQS: FC<FAQSProps> = ({ className, ...rest }: FAQSProps) => (
  <Box
    className={className}
    sx={{
      backgroundColor: 'background.dark',
      pt: 12,
      pb: 12,
      '& dt': {
        marginTop: 2,
      },
      '& dd': {
        marginTop: 2,
      },
    }}
    {...rest}
  >
    <Container maxWidth="lg">
      <Typography variant="h1" color="textPrimary">
        Frequently asked questions
      </Typography>
      <Box my={3}>
        <Divider />
      </Box>
      <Grid container spacing={3} component="dl">
        <Grid item xs={12} md={6}>
          <Typography variant="overline" color="secondary">
            Account &amp; Usage
          </Typography>
          <Box mt={6}>
            <dt>
              <Typography variant="h4" color="textPrimary">
                Is this app free?
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1" color="textSecondary">
                MyDeepWork app is in beta development and free for use until the
                full release.
              </Typography>
            </dd>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline" color="secondary">
            Help
          </Typography>
          <Box mt={6}>
            <dt>
              <Typography variant="h4" color="textPrimary">
                Any help available?
              </Typography>
            </dt>
            <dd>
              <Typography variant="body1" color="textSecondary">
                Please read our{' '}
                <a href={HELP_CENTER} target="_blank" rel="noreferrer">
                  Get Started Guide
                </a>
                .
              </Typography>
            </dd>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default FAQS;

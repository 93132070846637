import React, {
  forwardRef,
  // useEffect,
  // useCallback,
  HTMLProps,
  ReactNode,
} from 'react';
//import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
//import { logAppEvent } from 'api/analytics';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

// eslint-disable-next-line react/display-name
const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ title, children, ...rest }: PageProps, ref) => {
    // const location = useLocation();

    // const sendPageViewEvent = useCallback(() => {
    //   logAppEvent('page_view', {
    //     firebase_screen: location.pathname + location.search,
    //   });
    // }, [location]);

    // useEffect(() => {
    //   sendPageViewEvent();
    // }, [sendPageViewEvent]);

    return (
      <div ref={ref} {...rest}>
        <Helmet titleTemplate="%s | My Deep Work">
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);

export default Page;

export const to00 = (m: number) => (m > 9 ? `${m}` : `0${m}`);

export const formatTimestamp = (min: number, displayMinutes = true) => {
  const h = Math.floor(min / 60);
  const m = displayMinutes ? `:${to00(min % 60)}` : '';
  const p = h >= 12 ? ' pm' : ' am';
  const ph = h > 12 ? h - 12 : h;
  return `${h === 0 ? 12 : ph}${m}${p}`;
};

export const formatSeconds = (s?: number) => {
  if (s === undefined) {
    return '';
  }
  return `${to00(Math.floor(s / 60))}:${to00(s % 60)}`;
};

export const formatMinutes = (min: number) => {
  const h = Math.trunc(min / 60);
  const m = Math.floor(Math.abs(min) % 60);
  return h !== 0 ? `${h}h ${min !== 0 ? `${to00(m)}m` : ''}` : `${min}m`;
};

export const toHours = (min: number) => {
  const h = Math.round((min / 60) * 10) / 10;
  return min <= 5 ? '' : `${h}`;
};

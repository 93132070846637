import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar, Typography, Link } from '@mui/material';
import Logo from 'components/app/Logo';

import { APP_VERSION, HELP_CENTER } from '../../shared/constants';

// const PREFIX = 'TopBar';

// const classes = {
//   root: `${PREFIX}-root`,
//   toolbar: `${PREFIX}-toolbar`,
//   logo: `${PREFIX}-logo`,
//   link: `${PREFIX}-link`,
//   divider: `${PREFIX}-divider`,
// };

// const StyledAppBar = styled(AppBar)(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.default,
//   },

//   [`& .${classes.toolbar}`]: {
//     height: 64,
//   },

//   [`& .${classes.logo}`]: {
//     marginRight: theme.spacing(2),
//   },

//   [`& .${classes.link}`]: {
//     fontWeight: theme.typography.fontWeightMedium,
//     '& + &': {
//       marginLeft: theme.spacing(2),
//     },
//   },

//   [`& .${classes.divider}`]: {
//     width: 1,
//     height: 32,
//     marginLeft: theme.spacing(2),
//     marginRight: theme.spacing(2),
//   },
// }));

interface TopBarProps {
  className?: string;
}

const TopBar: FC<TopBarProps> = ({ className, ...rest }: TopBarProps) => (
  <AppBar
    className={className}
    sx={{
      backgroundColor: 'background.default',
    }}
    color="default"
    {...rest}
  >
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <Logo />
      </RouterLink>

      <Typography sx={{ marginLeft: 3 }}>My Deep Work </Typography>
      <Typography
        sx={{ marginLeft: 2 }}
        variant="caption"
        color="textSecondary"
      >
        &nbsp;v {APP_VERSION}
      </Typography>
      <Box flexGrow={1} />
      <Link
        sx={{
          fontWeight: 'medium',
        }}
        color="textSecondary"
        component="a"
        href={HELP_CENTER}
        target="_blank"
        underline="none"
        variant="body2"
      >
        Help
      </Link>
      <Button
        color="secondary"
        component={RouterLink}
        to="/app"
        variant="contained"
        size="small"
        sx={{
          marginLeft: 4,
        }}
      >
        Start
      </Button>
    </Toolbar>
  </AppBar>
);

export default TopBar;

import { remark } from 'remark';
import remarkGfm from 'remark-gfm';
import remarkRehype from 'remark-rehype';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeStringify from 'rehype-stringify';

import strip from 'strip-markdown';

export const markdownToHtml = (text) => {
  if (!text) return '';

  const content = remark()
    .use(remarkGfm)
    .use(remarkRehype)
    .use(rehypeExternalLinks, {
      target: '_blank',
      rel: ['nofollow', 'noreferrer', 'noopener'],
    })
    .use(rehypeStringify)
    .processSync(text)
    .toString();

  return content;
};

export const markdownStrip = (text) => {
  const content = remark().use(strip).processSync(text).toString().trim();

  return content;
};

import React from 'react';
import { formatMinutes } from 'models/time';
import { Box, Tooltip, Typography } from '@mui/material';
import { TaskStat } from 'models/task';
import {
  lightGreen,
  green,
  grey,
  orange,
  red,
  blue,
} from '@mui/material/colors';

export type TaskStatChartProps = {
  stat: TaskStat;
  condensed?: boolean;
};

export const TaskStatChart: React.FC<TaskStatChartProps> = ({
  stat,
  condensed,
}: TaskStatChartProps) => {
  const total = Math.max(
    stat.done + stat.remaining,
    stat.done + stat.available + stat.scheduled,
    1
  );

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 300,
        position: 'relative',
        cursor: 'pointer',
        '& .stack': {
          display: 'flex',
        },
        '&.archived': {
          border: `2px solid ${green[700]}`,
        },
        '& .planned': {
          height: 22,
          borderRight: '2px solid',
          position: 'absolute',
          top: -1,
        },
        '& .inner': {
          height: 26,
          border: '1px solid',
          overflow: 'hidden',
          padding: '3px 4px',
          position: 'relative',
          textAlign: 'center',
          fontSize: 10,
        },
        '& .bar': {
          height: 5,
          overflow: 'hidden',
          padding: '1px 2px',
          position: 'relative',
        },
      }}
    >
      <Tooltip
        title={
          <Typography variant="caption">
            <Typography variant="caption" component="span">
              ☆ <b>{stat.score || '-'}</b>
              {stat.score !== stat.totalScore && (
                <span>/{stat.totalScore}</span>
              )}{' '}
              pts.
            </Typography>
            <br />
            {stat.done > 0 && (
              <span>
                <b>Done:</b> {formatMinutes(stat.done)}{' '}
                {stat.countDone > 0 && <span>({stat.countDone})</span>}{' '}
                {!condensed && <span> / {formatMinutes(stat.planned)}</span>}
              </span>
            )}{' '}
            <br />
            {(!condensed ||
              ((stat.remaining !== -stat.delta || stat.delta > 0) &&
                stat.remaining !== 0 &&
                stat.remaining !== stat.scheduled)) && (
              <span>
                <b>Remaining:</b> {formatMinutes(stat.remaining)}
              </span>
            )}{' '}
            {stat.count - stat.countDone > 0 && (
              <span>({stat.count - stat.countDone} tasks)</span>
            )}{' '}
            {stat.delta !== 0 && (!condensed || stat.delta < 0) && (
              <Typography
                variant="caption"
                component="span"
                sx={{
                  color: stat.delta < 0 ? red[200] : 'inherit',
                }}
              >
                {' '}
                Δ{formatMinutes(stat.delta)}
              </Typography>
            )}
            <br />
            {stat.available > 0 &&
              stat.available + stat.scheduled < stat.remaining && (
                <span>
                  <b>Available:</b> {formatMinutes(stat.available)}
                </span>
              )}{' '}
            {stat.scheduled > 0 && (
              <span>
                <b>Scheduled:</b> {formatMinutes(stat.scheduled)}
              </span>
            )}{' '}
          </Typography>
        }
      >
        <div>
          {stat.score < stat.totalScore && stat.score > 5 && (
            <div className="stack">
              <div
                className="bar"
                style={{
                  width: `${
                    (stat.score / stat.totalScore) *
                    // (1 - Math.abs(stat.delta) / total) *
                    100
                  }%`,
                  backgroundColor: green[200],
                }}
              ></div>
              <div
                className="bar"
                style={{
                  width: `${
                    ((stat.totalScore - stat.score) / stat.totalScore) * 100
                  }%`,
                  backgroundColor: orange[100],
                }}
              ></div>
            </div>
          )}
          <div className="stack">
            {stat.done > 5 && (
              <div
                className="inner"
                style={{
                  width: `${(stat.done / total) * 100}%`,
                  backgroundColor: green[100],
                  borderColor: green[200],
                }}
              >
                {stat.done / total > 0.2 && (
                  <Typography variant="caption">
                    {formatMinutes(stat.done)} ({stat.countDone}) ☆{' '}
                    {stat.score || '-'}
                  </Typography>
                )}
              </div>
            )}
            {stat.scheduled > 5 && (
              <div
                className="inner"
                style={{
                  width: `${(stat.scheduled / total) * 100}%`,
                  backgroundColor: lightGreen[100],
                  borderColor: lightGreen[200],
                }}
              >
                {stat.scheduled / total > 0.2 && (
                  <Typography variant="caption">
                    {formatMinutes(stat.scheduled)}
                  </Typography>
                )}
              </div>
            )}
            {stat.available > 0 && (
              <div
                className="inner"
                style={{
                  width: `${(stat.available / total) * 100}%`,
                  backgroundColor: grey[100],
                  borderColor: grey[200],
                }}
              >
                {stat.available / total > 0.2 && (
                  <Typography variant="caption">
                    {formatMinutes(stat.available)}
                    {stat.count > stat.countDone && (
                      <> ({stat.count - stat.countDone}) </>
                    )}
                    {stat.totalScore > stat.score && (
                      <> ☆ {stat.totalScore - stat.score}</>
                    )}
                  </Typography>
                )}
              </div>
            )}
            {Math.abs(stat.delta) > 5 && (
              <div
                className="inner"
                style={{
                  width: `${(Math.abs(stat.delta) / total) * 100}%`,
                  backgroundColor: stat.delta > 0 ? blue[50] : red[100],
                  borderColor: stat.delta > 0 ? blue[100] : red[200],
                }}
              >
                {Math.abs(stat.delta) / total > 0.2 && (
                  <Typography variant="caption">
                    {formatMinutes(stat.delta)}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div
          className="inner"
          style={{
            width: `${(stat.done / (stat.planned || 1)) * 100}%`,
            backgroundColor: green[100],
            borderColor: green[300],
          }}
        >
          <Typography variant="caption">{formatMinutes(stat.done)}</Typography>
        </div>
      </div>
      </div> */}
      </Tooltip>
    </Box>
  );
};

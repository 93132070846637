import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import useWorkContext from 'hooks/useWorkContext';
import { TaskStat } from 'models/task';
import { getDayStat } from 'features/day/task-stat';
import useDebouncedEffect from 'hooks/useDebounceEffect';
import { formatMinutes } from 'models/time';
import { TaskStatChart } from './TaskStatChart';

const SessionBar: FC = () => {
  const { todayModel, currentTime, todaySchedule } = useWorkContext();

  const [dayStat, setDayStat] = useState<TaskStat>();

  useDebouncedEffect(
    () => {
      const stat = todayModel?.tasks
        ? getDayStat(todayModel, todaySchedule, currentTime)
        : null;
      setDayStat(stat);
    },
    1000,
    [currentTime, todaySchedule, todayModel]
  );

  return (
    <>
      {dayStat && (
        <>
          <Typography variant="caption">
            Today to do:{' '}
            <b>
              {formatMinutes(dayStat.remaining)} ({dayStat.countDone}/
              {dayStat.count})
            </b>
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <TaskStatChart stat={dayStat} />
          </Box>
        </>
      )}
    </>
  );
};

export default SessionBar;

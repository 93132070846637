import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  title: string;
  isOpenNav: boolean;
  selected?: boolean;
}

const NavItem: FC<NavItemProps> = ({
  children,
  depth,
  href,
  icon: Icon,
  title,
  isOpenNav,
  selected,
}: NavItemProps) => {
  const [open, setOpen] = useState<boolean>(selected);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const style = {
    paddingLeft: isOpenNav ? '8px' : '4px',
  };

  if (children) {
    return (
      <>
        <ListItemButton onClick={handleToggle} sx={style}>
          <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
          {isOpenNav && (
            <>
              <ListItemText primary={title} />
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </>
          )}
        </ListItemButton>
        <Collapse in={open}>
          <Box
            style={{
              paddingLeft: isOpenNav ? depth * 16 + 16 + 'px' : 0,
            }}
          >
            {children}
          </Box>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemButton
      component={RouterLink}
      to={href}
      selected={selected}
      style={style}
    >
      <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
      {isOpenNav && <ListItemText primary={title} />}
    </ListItemButton>
  );
};

export default NavItem;

import { v4 as uuidv4 } from 'uuid';
import { MINUTES_INC } from 'features/day/day-types';
import { markdownStrip } from 'utils/markdown';
import rulebook from 'features/rulebook';

export type TaskProgress = {
  value?: number;
  estValue?: number;
  updated: string;
};

export type Task = {
  id: string;
  content: string;
  created_at: string;
  updated_at: string;
  next?: string;
  completed: boolean;
  category: string;
  color?: number;
  clone?: boolean;
  minutes?: number;
  break?: number;
  rank?: number;
  split?: number;
  brief?: string;
  progress?: TaskProgress;
  boost?: number;
  local?: boolean;
  localBrief?: boolean;
  contentHtml?: string;
  briefHtml?: string;
  contentShort?: string;
};

export type TaskStat = {
  planned: number;
  done: number;
  remaining: number;
  available: number;
  delta: number;
  scheduled: number;
  totalScore: number;
  score: number;
  start?: number;
  end?: number;
  count: number;
  countDone: number;
};

export type TaskInfo = {
  task: Task;
  stat?: TaskStat;
};

export type SessionInfo = {
  runningSeconds: number;
  pausedSeconds: number;
};

export type TaskUpdate = {
  taskId: string;
  data: Partial<Task>;
};

export type WorkCategory = {
  id: string;
  name: string;
  color?: string;
};

export const newTask = (props?: Partial<Task>): Task => ({
  id: uuidv4(),
  created_at: new Date().toJSON(),
  updated_at: new Date().toJSON(),
  content: '',
  contentHtml: '',
  category: 'regular',
  completed: false,
  boost: 1,
  minutes: rulebook.task.defaultDuration,
  break: rulebook.task.defaultBreak,
  ...props,
});

export const shortenName = (
  target: { content: string; split?: number },
  limit = 50,
  plain = false,
  textOnly = false
) => {
  if (!target?.content) {
    return '';
  }

  const content = markdownStrip(target.content);
  let name = (target.split && !plain ? `${target.split}: ` : '') + content;

  if (textOnly) {
    name = name.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').trim();
  }

  if (name.length > limit) {
    return `${name.substr(0, limit)}...`;
  }
  return name;
};

export const getScore = (done: number, task: Task) =>
  Math.floor(
    (done / MINUTES_INC) * (task?.boost === undefined ? 1 : task.boost)
  );

export const workCategories: WorkCategory[] = [
  {
    id: 'deep',
    name: 'Deep Work',
    color: 'primary',
  },
  {
    id: 'regular',
    name: 'Regular Work',
    color: 'warning',
  },
  {
    id: 'shallow',
    name: 'Shallow Work',
    color: 'secondary',
  },
];

import React from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListSubheader,
  Typography,
  IconButton,
  SvgIcon,
  useTheme,
} from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';

import BarChartIcon from '@mui/icons-material/BarChart';
import TodayIcon from '@mui/icons-material/Today';
import TaskIcon from '@mui/icons-material/Assignment';
import HelpIcon from '@mui/icons-material/Help';

import { HELP_CENTER } from 'shared/constants';
import SessionBar from 'components/tasks/SessionBar';

import NavItem from './NavItem';
import Profile from './Profile';
import { AppTheme } from 'theme';

interface Item {
  href?: string;
  icon?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/dashboard',
      },
      {
        title: 'My Day',
        icon: TodayIcon,
        href: '/app/day',
      },
      {
        title: 'Tasks',
        icon: TaskIcon,
        href: '/app/tasks',
      },
    ],
  },
];

function renderNavItems({
  items,
  pathname,
  isOpenNav,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  isOpenNav: boolean;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            isOpenNav,
            depth,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  isOpenNav,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  isOpenNav: boolean;
  depth: number;
}) {
  const key = item.title + depth;

  const open = matchPath(pathname, {
    path: item.href,
    exact: false,
  });

  acc.push(
    <NavItem
      depth={depth}
      icon={item.icon}
      href={item.href}
      key={key}
      selected={Boolean(open)}
      title={item.title}
      isOpenNav={isOpenNav}
    >
      {item.items &&
        renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          isOpenNav,
        })}
    </NavItem>
  );

  return acc;
}

interface NavBarProps {
  onNavBarChange: () => void;
  openNavBar: boolean;
}

const NavBar: FC<NavBarProps> = ({
  onNavBarChange,
  openNavBar,
}: NavBarProps) => {
  const location = useLocation();
  const theme = useTheme() as AppTheme;
  const drawerWidth = openNavBar ? theme.frame.navBarWidth : 64;
  const drawerHeight = `calc(100% - ${theme.frame.bottom + theme.frame.top}px)`;
  const drawerHeightSm = `calc(100% - ${
    theme.frame.bottomSm + theme.frame.top
  }px)`;

  return (
    <div>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          height: [drawerHeightSm, drawerHeight],
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: [drawerHeightSm, drawerHeight],
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
          },
        }}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box pl={1}>
              <IconButton color="inherit" onClick={onNavBarChange} size="large">
                <SvgIcon fontSize="small">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            </Box>

            <Box px={2} pb={2}>
              <Profile openNavBar={openNavBar} />
            </Box>
            <Divider />
            <Box p={2}>
              {sections.map((section) => (
                <List
                  key={section.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                    isOpenNav: openNavBar,
                  })}
                </List>
              ))}
            </Box>
            {openNavBar && (
              <>
                <Divider />
                <Box p={2}>
                  <SessionBar></SessionBar>
                </Box>
                <Box p={2}>
                  <Box p={2} borderRadius={1} bgcolor="background.dark">
                    <Typography variant="h6" color="textPrimary">
                      Need Help?
                    </Typography>
                    <Link
                      variant="subtitle1"
                      color="secondary"
                      component="a"
                      href={HELP_CENTER}
                      target="_blank"
                    >
                      Check our guide
                    </Link>
                  </Box>
                </Box>
              </>
            )}
            {!openNavBar && (
              <Box p={2}>
                <Link
                  variant="subtitle1"
                  color="secondary"
                  component="a"
                  href={HELP_CENTER}
                  target="_blank"
                  style={{
                    paddingLeft: '4px',
                  }}
                >
                  <HelpIcon color="action" />
                </Link>
              </Box>
            )}
          </PerfectScrollbar>
        </Box>
      </Drawer>
    </div>
  );
};

export default NavBar;

import React from 'react';
import type { FC } from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';

// const PREFIX = 'Hero';

// const classes = {
//   root: `${PREFIX}-root`,
//   technologyIcon: `${PREFIX}-technologyIcon`,
//   image: `${PREFIX}-image`,
//   shape: `${PREFIX}-shape`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.dark,
//     paddingTop: 50,
//     paddingBottom: 50,
//     [theme.breakpoints.down('lg')]: {
//       paddingTop: 60,
//       paddingBottom: 60,
//     },
//   },

//   [`& .${classes.technologyIcon}`]: {
//     height: 40,
//     margin: theme.spacing(1),
//   },

//   [`& .${classes.image}`]: {
//     perspectiveOrigin: 'left center',
//     transformStyle: 'preserve-3d',
//     perspective: 1500,
//     '& > img': {
//       maxWidth: '50%',
//       height: 'auto',
//       transform: 'rotateY(-35deg) rotateX(15deg)',
//       backfaceVisibility: 'hidden',
//       boxShadow: theme.shadows[16],
//     },
//   },

//   [`& .${classes.shape}`]: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     '& > img': {
//       maxWidth: '90%',
//       height: 'auto',
//     },
//   },
// }));

interface HeroProps {
  className?: string;
}

const Hero: FC<HeroProps> = ({ className, ...rest }: HeroProps) => {
  const theme = useTheme();
  return (
    <Box
      className={className}
      sx={{
        backgroundColor: 'background.dark',
        pt: 8,
        pb: 8,
      }}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="overline" color="secondary">
                Make things happen
              </Typography>
              <Typography variant="h1" color="textPrimary">
                My Deep Work
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  A web app that helps to plan and execute your work days and
                  dreams.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  perspectiveOrigin: 'left center',
                  transformStyle: 'preserve-3d',
                  perspective: 1500,
                  '& > img': {
                    maxWidth: '50%',
                    height: 'auto',
                    transform: 'rotateY(-35deg) rotateX(15deg)',
                    backfaceVisibility: 'hidden',
                    boxShadow: theme.shadows[16],
                  },
                }}
              >
                <img alt="Presentation" src="/static/home/work.jpg" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;

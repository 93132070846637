export const APP_VERSION = '0.0.2 (alpha)';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const HELP_CENTER = 'https://github.com/MyDeepWork/Community';

export const CONTROL_BAR = {
  width: 256,
  bottom: 64,
  bottomSm: 90,
};

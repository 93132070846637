import store from 'store';
import {
  DayItem,
  DayModel,
  getDayItem,
  // ScheduleInterval,
  TaskIntentRecord,
} from 'features/day/day-types';
import { addDays } from 'date-fns';
import { loadDayModels } from 'api/day-api';
import { saveLogItem } from 'api/log-api';
import { getFullTaskStat, getDayStat } from 'features/day/task-stat';
import { TaskStat, TaskProgress } from 'models/task';
import { storeStatDay } from 'slices/stat-slice';
import { DayStatRecord } from 'features/stat/stat';
import {
  // isPastModel,
  getCurrentTime,
  isSameHourTime,
} from 'features/day/day-layer';
import { saveStatRecordProp } from 'api/stat-api';

export type LogType = 'day' | 'partial';

export type DayItemLog = {
  key: string;
  type: LogType;
  date: string;
  time: number;
  item?: DayItem;
  stat: TaskStat;
  tasks: number;
  progress?: TaskProgress;
};

export const getDayItemLogKey = (
  taskId: string,
  model: DayModel,
  currentTime?: number
) =>
  `${taskId ? `${taskId}-` : ''}${model.date}${
    currentTime ? `-${currentTime}` : ''
  }`;

export const filterIntent = (
  taskId: string,
  model: DayModel
): TaskIntentRecord[] => {
  const intent = [];
  let ti: TaskIntentRecord = null;
  const history = model.intent?.find((i) => i?.item?.id === taskId)?.history;
  if (history) {
    history.forEach((i) => {
      if (ti && !isSameHourTime(ti.time, i.time)) {
        intent.push(ti);
      }
      ti = i;
    });
    if (ti && ti.done > 0) {
      intent.push(ti);
    }
  }
  return intent;
};

export const updateDayItemLog = async (
  taskId: string,
  model: DayModel,
  currentTime: number,
  // schedule: ScheduleInterval[],
  type?: LogType
) => {
  const tasks = model.tasks.filter(
    (t) => (taskId && t.id === taskId) || (!taskId && !t.completed)
  );
  if (!tasks.length) {
    return;
  }

  // schedule = isPastModel(model) ? model.plan : schedule ?? model.plan;
  currentTime = getCurrentTime(model, currentTime);

  const task = taskId ? tasks[0] : null;
  const stat = taskId
    ? getFullTaskStat(task, model, null, currentTime)
    : getDayStat(model, null, currentTime);
  const hm = type === 'partial' ? Math.floor(currentTime / 60) * 60 : null;

  const log: DayItemLog = {
    key: getDayItemLogKey(taskId, model, hm),
    type: type ?? 'day',
    date: model.date,
    item: task ? getDayItem(task, null, false) : null,
    stat,
    time: currentTime,
    tasks: tasks.length,
  };
  if (task?.progress) {
    log.progress = { ...task?.progress };
  }
  await saveLogItem(log);

  if (!taskId && type === 'partial') {
    await saveLogItem({
      ...log,
      key: getDayItemLogKey(taskId, model),
      type: 'day',
    });
  }

  if (!stat) {
    return;
  }

  const s: DayStatRecord = {
    done: stat.done,
    score: stat.score,
    planned: stat.planned,
  };
  if (log.progress?.estValue > 0) {
    s.remaining = Math.max(
      log.progress?.estValue - log.progress?.value ?? 0,
      0
    );
  }
  if (task) {
    const intent = filterIntent(task.id, model);
    if (intent.length > 0) {
      s.intent = intent;
    }
  }
  await store.dispatch(storeStatDay(model.date, s, task));
};

export const batchUpdateDayStat = async (update: (message: string) => any) => {
  update('started...');
  const models = await loadDayModels(
    addDays(new Date(), -120),
    addDays(new Date(), -1)
  );
  models.forEach(async (m, i) => {
    if (m.tasks && m.plan) {
      const taskIds = [...Array.from(new Set(m.tasks.map((t) => t.id)))];
      taskIds.forEach(async (t, j) => {
        await updateDayItemLog(t, m, 24 * 60);
        update(`${i}/${models.length} ${m.date}: ${j}/${taskIds.length}`);
      });
      await updateDayItemLog(null, m, 24 * 60);
    }
  });
  update('finished...');
};

export const batchUpdateTaskIntentHistory = async (
  update: (message: string) => any
) => {
  update('started...');
  const models = await loadDayModels(
    addDays(new Date(), -60),
    addDays(new Date(), -1)
  );
  models.forEach(async (m) => {
    if (m.tasks && m.plan) {
      const taskIds = [...Array.from(new Set(m.tasks.map((t) => t.id)))];
      taskIds.forEach(async (id) => {
        const intent = filterIntent(id, m);
        await saveStatRecordProp(
          id,
          m.date,
          'intent',
          intent.length > 1 ? intent : null
        );
        update(`${m.date} - ${id}`);
      });
    }
  });
  update('finished...');
};

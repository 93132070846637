import React from 'react';
import { GlobalStyles } from '@mui/material';
import type { FC } from 'react';
// import { createStyles } from '@mui/material';

// const useStyles = makeStyles(() =>
//   createStyles({
//     '@global': {
//       '*': {
//         boxSizing: 'border-box',
//         margin: 0,
//         padding: 0,
//       },
//       html: {
//         '-webkit-font-smoothing': 'antialiased',
//         '-moz-osx-font-smoothing': 'grayscale',
//         height: '100%',
//         width: '100%',
//       },
//       body: {
//         height: '100%',
//         width: '100%',
//       },
//       '#root': {
//         height: '100%',
//         width: '100%',
//       },
//     },
//   })
// );

const AppGlobalStyles: FC = () => (
  <GlobalStyles
    styles={{
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        // WebkitFontSmoothing: 'antialiased',
        // MozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    }}
  />
);

export default AppGlobalStyles;

import React, { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => (
  <img alt="Logo" src="/static/mdw-logo.svg" {...props} />
);

export default Logo;

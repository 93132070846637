import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';

// const PREFIX = 'CTA';

// const classes = {
//   root: `${PREFIX}-root`,
//   browseButton: `${PREFIX}-browseButton`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.default,
//     paddingTop: 128,
//     paddingBottom: 128,
//   },

//   [`& .${classes.browseButton}`]: {
//     marginLeft: theme.spacing(2),
//   },
// }));

interface CTAProps {
  className?: string;
}

const CTA: FC<CTAProps> = ({ className, ...rest }: CTAProps) => (
  <Box
    className={className}
    sx={{
      backgroundColor: 'background.default',
      pt: 12,
      pb: 12,
    }}
    {...rest}
  >
    <Container maxWidth="lg">
      <Typography variant="h1" align="center" color="textPrimary">
        Ready to make things happen?
      </Typography>
      <Box mt={6} display="flex" justifyContent="center" alignItems="center">
        <Button
          color="secondary"
          component={RouterLink}
          to="/app"
          variant="contained"
        >
          Start My Deep Work
        </Button>
      </Box>
    </Container>
  </Box>
);

export default CTA;

import store from 'store';
import * as Sentry from '@sentry/react';
import { AppUser } from 'models/user';

class UserContext {
  private static user: AppUser | null;

  static isTest = false;

  private static impersonated = false;

  static getStoredUid = () => window.localStorage.getItem('uid');

  static setUser = (user: AppUser | null, impersonated: boolean) => {
    UserContext.user = user;
    UserContext.impersonated = impersonated;
    if (user) {
      window.localStorage.setItem('uid', user.uid);
      Sentry.setUser({ email: user.email });
    } else {
      UserContext.removeStoredUid();
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    store.dispatch({ type: 'RESET' });
  };

  static removeStoredUid = () => {
    window.localStorage.removeItem('uid');
  };

  static updateUser = (update: Partial<AppUser>) => {
    if (UserContext.user) UserContext.user = { ...UserContext.user, ...update };
  };

  static getUser = () => UserContext.user;

  static uid = () => UserContext.user?.uid;

  static isImpersonated = () => UserContext.impersonated;
}

export default UserContext;

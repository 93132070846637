import React, { useCallback, useMemo, useState, useEffect } from 'react';
import type { FC } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DoneIcon from '@mui/icons-material/Done';
import PauseIcon from '@mui/icons-material/Pause';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import TodayIcon from '@mui/icons-material/Today';
import TaskIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'components/app/Logo';
import { formatSeconds } from 'models/time';
import useWorkContext from 'hooks/useWorkContext';
import { ModelAction } from 'models/model-types';
import { Task, TaskInfo, shortenName, SessionInfo } from 'models/task';
import { applyTaskAction, getRunningTask } from 'features/day/session';
import { updateDayModel, handleDayTaskAction } from 'features/tasks/task-func';
import RouteNav from 'features/app/route-nav';
import { DayModel, getDaySeconds } from 'features/day/day-types';
import { getTaskInfo } from 'features/day/task-stat';

// const PREFIX = 'ControlBar';

// const classes = {
//   root: `${PREFIX}-root`,
//   controls: `${PREFIX}-controls`,
//   playIcon: `${PREFIX}-playIcon`,
//   sessionInfo: `${PREFIX}-sessionInfo`,
// };

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
// const Root = styled('div')(({ theme }) => ({
//   [`& .${classes.root}`]: {},

//   [`& .${classes.controls}`]: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     paddingRight: theme.spacing(2),
//   },

//   [`& .${classes.playIcon}`]: {
//     height: 38,
//     width: 38,
//   },

//   [`& .${classes.sessionInfo}`]: {
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     display: 'inline-block',
//     maxWidth: '30vw',
//     '& .color-box': {
//       display: 'inline-block',
//       height: 12,
//       width: 12,
//       marginTop: 4,
//       marginRight: 5,
//       borderRadius: 4,
//     },
//   },
// }));

const ControlBar: FC = () => {
  const { todayModel, currentTime, todaySchedule, openTaskEdit } =
    useWorkContext();

  const [taskInfo, setTaskInfo] = useState<TaskInfo>();
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>();

  const getSessionInfo = (model: DayModel): SessionInfo => {
    const runningSeconds = model.session?.startedSeconds
      ? getDaySeconds() - model.session.startedSeconds
      : null;
    const pausedSeconds = model.session?.pausedSeconds
      ? getDaySeconds() - model.session.pausedSeconds
      : null;

    return { runningSeconds, pausedSeconds };
  };

  const getRunningSeconds = (si: SessionInfo) => {
    const pt = si?.pausedSeconds || 0;
    const rt = (si?.runningSeconds || 0) - pt;
    return rt < 0 ? 0 : rt;
  };

  useEffect(() => {
    const task = getRunningTask(todayModel);
    const ti = getTaskInfo(task, todaySchedule, currentTime);
    setTaskInfo(ti);

    const intervalId = setInterval(() => {
      const si = task ? getSessionInfo(todayModel) : null;
      setSessionInfo(si);

      const timer =
        todayModel?.session?.item?.state === 'stop'
          ? ''
          : `${formatSeconds(getRunningSeconds(si))} - `;

      const title = `${task ? `${timer}${shortenName(task)}` : 'Idle'}`;

      window.document.title = `${title} | My Deep Work`;
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentTime, todaySchedule, todayModel]);

  const label = useMemo(() => {
    const isBreak = todayModel?.session?.item?.state === 'break';

    let verb = '';
    if (isBreak) verb = 'Paused';
    else if (todayModel?.session?.item?.state === 'on') verb = 'Working on';
    else if (todayModel?.session?.item?.state === 'stop') verb = 'Ready for';

    const pt = sessionInfo?.pausedSeconds || 0;
    const timer =
      todayModel?.session?.item?.state === 'stop'
        ? ''
        : `${formatSeconds(getRunningSeconds(sessionInfo))} - `;
    return taskInfo?.task ? (
      <>
        {timer}
        {taskInfo?.stat?.done} / {taskInfo?.stat?.planned}
        {` ${verb} `}
        {isBreak && <span> [{formatSeconds(pt)}] </span>}
        {(!isBreak || pt % 5 !== 0) && (
          <>
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                height: 12,
                width: 12,
                marginTop: '4px',
                marginRight: 1,
                borderRadius: 4,
              }}
              className="color-box"
              style={{
                backgroundColor: `hsla(${taskInfo.task.color}, 60%, 60%`,
              }}
            ></Box>
            <span>
              {taskInfo?.task.contentShort ?? shortenName(taskInfo?.task)}
            </span>
          </>
        )}
      </>
    ) : (
      ' Idle'
    );
  }, [
    sessionInfo,
    taskInfo?.stat?.done,
    taskInfo?.stat?.planned,
    taskInfo?.task,
    todayModel?.session?.item?.state,
  ]);

  const availableTasks = useMemo(
    () => todayModel?.tasks?.filter((t) => !t.completed) || [],
    [todayModel?.tasks]
  );

  const runningIndex = useMemo(() => {
    const index = taskInfo?.task?.id
      ? availableTasks.findIndex((t) => t.id === taskInfo.task.id)
      : -1;
    return index;
  }, [availableTasks, taskInfo?.task.id]);

  const handleTaskSessionAction = useCallback(
    async (action: ModelAction<Task>) => {
      const m = await applyTaskAction(todayModel, action, currentTime);
      await updateDayModel(m, currentTime, true);
    },
    [todayModel, currentTime]
  );

  const handleTaskEditAction = useCallback(
    async (action: ModelAction<Task>) => {
      if (!action) {
        openTaskEdit(null);
        return;
      }
      if (action.type === 'edit') {
        openTaskEdit({ model: action.model, onAction: () => {} });
      } else {
        openTaskEdit(null);
        handleDayTaskAction(todayModel, action, currentTime);
      }
    },
    [currentTime, openTaskEdit, todayModel]
  );

  const handleTaskNav = useCallback(
    async (direction: number) => {
      const action: ModelAction<Task> = {
        type: 'start',
        args: 'stop',
      };
      const index = runningIndex + direction;
      if (index >= 0 && index < availableTasks.length) {
        action.model = availableTasks[index];
      }
      if (action.model) {
        handleTaskSessionAction(action);
      }
    },
    [availableTasks, handleTaskSessionAction, runningIndex]
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box ml={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          <RouterLink to="/app/dashboard">
            <Logo />
          </RouterLink>
        </Box>
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            {taskInfo && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="previous"
                  onClick={() => handleTaskNav(-1)}
                  disabled={runningIndex <= 0}
                  size="large"
                >
                  <SkipPreviousIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="stop"
                  onClick={() => {
                    handleTaskSessionAction({
                      type: 'start',
                      model: taskInfo.task,
                      args: 'stop',
                    });
                  }}
                  size="large"
                >
                  <StopIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="run"
                  onClick={() => {
                    handleTaskSessionAction({
                      type: 'start',
                      model: taskInfo.task,
                      args:
                        todayModel?.session?.item?.state !== 'on'
                          ? 'on'
                          : 'break',
                    });
                  }}
                  size="large"
                >
                  {todayModel?.session?.item?.state === 'on' && (
                    <PauseIcon
                      sx={{
                        height: 38,
                        width: 38,
                      }}
                    />
                  )}
                  {todayModel?.session?.item?.state !== 'on' && (
                    <PlayArrowIcon
                      sx={{
                        height: 38,
                        width: 38,
                      }}
                    />
                  )}
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="done"
                  onClick={() => {
                    handleTaskSessionAction({
                      type: 'complete',
                      model: taskInfo.task,
                    });
                  }}
                  size="large"
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="next"
                  onClick={() => handleTaskNav(1)}
                  disabled={runningIndex >= availableTasks.length - 1}
                  size="large"
                >
                  <SkipNextIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            ml={2}
          >
            <Typography
              component="div"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: { xs: '90vw', md: '30vw' },
                fontSize: { xs: '0.9rem', md: '1rem' },
              }}
            >
              {label}
            </Typography>
          </Box>
        </Box>

        <Box mx={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          {taskInfo?.task && (
            <IconButton
              color="inherit"
              onClick={() =>
                openTaskEdit({
                  model: taskInfo.task,
                  onAction: handleTaskEditAction,
                })
              }
              size="large"
            >
              <TaskIcon />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            component={Link}
            to={RouteNav.dayView(null, 'schedule')}
            size="large"
          >
            <TodayIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ControlBar;

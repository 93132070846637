import React, { useState } from 'react';
import type { FC } from 'react';
// import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  IconButton,
  SvgIcon,
  Typography,
  CardActions,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Task, shortenName } from 'models/task';
import { Trash as TrashIcon } from 'react-feather';
import { ModelAction } from 'models/model-types';
import ColorPicker from 'components/ui/ColorPicker';
import { NumberInput } from 'components/tasks/NumberInput';
import Markdown from 'components/text/Markdown';
import { markdownToHtml } from 'utils/markdown';

// const PREFIX = 'TaskForm';

// const classes = {
//   root: `${PREFIX}-root`,
//   confirmButton: `${PREFIX}-confirmButton`,
//   actions: `${PREFIX}-actions`,
// };

// const StyledFormik = styled(Formik)(({ theme }) => ({
//   [`& .${classes.root}`]: {},

//   [`& .${classes.confirmButton}`]: {
//     marginLeft: theme.spacing(2),
//   },

//   [`& .${classes.actions}`]: {
//     justifyContent: 'flex-end',
//   },
// }));

interface TaskFormProps {
  task?: Task;
  className?: string;
  isNew?: boolean;
  onAction?: (action: ModelAction<Task>) => void;
}

const TaskForm: FC<TaskFormProps> = ({
  className,
  task,
  isNew,
  onAction,
  ...rest
}: TaskFormProps) => {
  const initialValues = {
    submit: null,
    syncTask: false,
    ...task,
  };

  const [edit, setEdit] = useState<{ title?: boolean }>({
    title: !task.content,
  });

  const [taskTitle, setTaskTitle] = useState(task.content);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        category: Yup.string().max(255),
        content: Yup.string().max(5000),
        completed: Yup.bool().required(),
        boost: Yup.number().min(0).max(10),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setStatus({ success: true });
          setSubmitting(false);
          if (values.content) {
            values.contentHtml = markdownToHtml(values.content);
            values.contentShort = shortenName(values);
          }
          if (values.brief) {
            values.briefHtml = markdownToHtml(values.brief);
          }
          const { submit, syncTask, clone, split, ...taskValues } = values;
          onAction({
            type: isNew ? 'create' : 'update',
            model: { ...task, ...taskValues },
            partial: taskValues,
            sync: syncTask,
          });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit} className={className} {...rest}>
          <Box p={2}>
            <Box mb={1}>
              <Card>
                {taskTitle && (
                  <>
                    <CardHeader
                      title={
                        <Typography variant="h5" color="textPrimary">
                          <Markdown text={taskTitle} />
                        </Typography>
                      }
                      onClick={() => {
                        setEdit({
                          ...edit,
                          title: true,
                        });
                      }}
                    ></CardHeader>
                    <Divider />
                  </>
                )}
                <CardContent>
                  {!edit.title && (
                    <Box
                      mt={2}
                      onClick={() => {
                        setEdit({
                          ...edit,
                          title: true,
                        });
                      }}
                    >
                      <Typography variant="caption" component="div">
                        <Markdown text={values.brief} />
                      </Typography>
                    </Box>
                  )}
                  {edit.title && (
                    <>
                      <TextField
                        error={Boolean(touched.content && errors.content)}
                        fullWidth
                        multiline
                        helperText={touched.content && errors.content}
                        label="Task Title"
                        name="content"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.content}
                        variant="outlined"
                        autoFocus={true}
                      />
                      <Box mt={2}>
                        <TextField
                          error={Boolean(touched.brief && errors.brief)}
                          fullWidth
                          multiline
                          helperText={touched.brief && errors.brief}
                          label="Task Brief"
                          name="brief"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.brief}
                          variant="outlined"
                        />
                      </Box>
                    </>
                  )}
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Box ml={2}>
                    {edit.title && (
                      <Typography variant="caption">
                        <a
                          href="https://www.markdownguide.org/cheat-sheet/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          markdown format help
                        </a>
                      </Typography>
                    )}
                  </Box>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (edit.title) {
                        setTaskTitle(values.content);
                      }
                      setEdit({
                        ...edit,
                        title: !edit.title,
                      });
                    }}
                  >
                    {edit.title && 'Apply'}
                    {!edit.title && 'Change'}
                  </Button>
                </CardActions>
              </Card>
            </Box>
            {(!isNew || values.content) && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="settings"
                  id="settings"
                >
                  <Typography>Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Box mt={3}>
                        <Card>
                          <CardHeader title="Planning" />
                          <Divider />
                          <CardContent>
                            <Box mt={2} display="flex">
                              <NumberInput
                                label="Plan (min)"
                                helperText="Planned session minutes"
                                value={values.minutes}
                                onChange={(minutes) => {
                                  setFieldValue('minutes', minutes);
                                  setFieldTouched('minutes', true);
                                }}
                              ></NumberInput>

                              <NumberInput
                                label="Break (min)"
                                helperText="Break after session"
                                value={values.break}
                                onChange={(minutes) => {
                                  setFieldValue('break', minutes);
                                  setFieldTouched('break', true);
                                }}
                              ></NumberInput>
                            </Box>
                          </CardContent>
                        </Card>
                        <Box mt={3}>
                          <Card>
                            <CardHeader title="Progress to completion (optional)" />
                            <Divider />
                            <CardContent>
                              <Box mt={2} display="flex">
                                <NumberInput
                                  label="Current progress"
                                  helperText="%, pages, steps, etc."
                                  value={values.progress?.value}
                                  onChange={(value) => {
                                    setFieldValue('progress.value', value);
                                    setFieldTouched('progress.value', true);
                                  }}
                                ></NumberInput>
                                <NumberInput
                                  label="Total estimated work"
                                  helperText="%, pages, steps, etc."
                                  value={values.progress?.estValue}
                                  onChange={(value) => {
                                    setFieldValue('progress.estValue', value);
                                    setFieldTouched('progress.estValue', true);
                                  }}
                                ></NumberInput>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                        <Box mt={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.completed}
                                onChange={handleChange}
                                value={values.completed}
                                name="completed"
                              />
                            }
                            label="Completed"
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box mt={3}>
                        <Card>
                          <CardHeader title="Value" />
                          <Divider />
                          <CardContent>
                            {/* <TextField
                      fullWidth
                      label="Category"
                      name="category"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.category}
                      variant="outlined"
                    >
                      {workCategories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </TextField> */}

                            <Box mt={2}>
                              <NumberInput
                                label="Score Boost (multiplier of task time)"
                                helperText="1 is regular, 10 - life-changing, 0.1 - little value"
                                value={values.boost}
                                onChange={(value) => {
                                  setFieldValue('boost', value);
                                  setFieldTouched('boost', true);
                                }}
                              ></NumberInput>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box mt={3}>
                        <Card>
                          <CardHeader title="Display" />
                          <Divider />
                          <CardContent>
                            <Box mt={2}>
                              <Typography
                                variant="caption"
                                component="div"
                                sx={{ my: 2 }}
                              >
                                Task Color
                              </Typography>
                              <ColorPicker
                                color={values.color}
                                onChange={(color) => {
                                  setFieldValue('color', color);
                                  setFieldTouched('color', true);
                                }}
                              ></ColorPicker>
                            </Box>
                          </CardContent>{' '}
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>

          <Divider />
          <Box p={2} display="flex" alignItems="center">
            {!isNew && (
              <IconButton
                onClick={() => onAction({ type: 'delete', model: task })}
                size="large"
              >
                <SvgIcon>
                  <TrashIcon />
                </SvgIcon>
              </IconButton>
            )}
            <Box flexGrow={1} />
            <Button onClick={() => onAction({ type: 'cancel', model: task })}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              color={task.clone ? 'inherit' : 'secondary'}
              sx={{
                marginLeft: 2,
              }}
              onClick={() => {
                setFieldValue('syncTask', false);
              }}
            >
              Save task
            </Button>
            {task.clone && (
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                color="secondary"
                sx={{
                  marginLeft: 2,
                }}
                startIcon={<SyncIcon />}
                onClick={() => {
                  setFieldValue('syncTask', true);
                }}
              >
                Save & Sync task
              </Button>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default TaskForm;

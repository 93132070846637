import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

type NumberInputProps = {
  label?: string;
  value?: number;
  inactive?: boolean;
  helperText?: string;
  onChange: (value: number) => void;
  width?: number;
  size?: 'lg';
};

export const NumberInput: React.FC<NumberInputProps> = ({
  label,
  value,
  onChange,
  inactive,
  helperText,
  width,
  size,
}: NumberInputProps) => {
  const [draft, setDraft] = useState(value);
  const [isActive, setActive] = useState(!inactive);

  useEffect(() => {
    setDraft(value);
  }, [value]);

  const handleChange = () => {
    const v = +draft;
    if (v !== value) {
      onChange(v);
    }
    if (inactive) {
      setActive(false);
    }
  };

  return (
    <Box component="span">
      {!isActive && (
        <Typography
          variant={size !== 'lg' ? 'caption' : undefined}
          onClick={() => setActive(true)}
          title="click to edit"
        >
          {label ? `${label} : ` : ''}
          <Box
            component="span"
            style={{
              padding: '1px 5px',
              backgroundColor: grey[100],
              fontWeight: size === 'lg' ? 'normal' : 'bold',
              borderRadius: '3px',
              cursor: 'text',
              display: 'inline-block',
            }}
          >
            {draft ?? 0}
          </Box>
        </Typography>
      )}
      {isActive && (
        <TextField
          type="number"
          inputProps={{
            //inputMode: 'numeric',
            pattern: '[0-9]+([\\.,][0-9]+)?',
            min: 0,
          }}
          size="small"
          variant="standard"
          value={draft || 0}
          label={label}
          helperText={helperText}
          style={{ width, paddingLeft: '5px' }}
          onChange={(e) => {
            const n = +e.target.value;
            setDraft(n);
          }}
          onBlur={(e) => {
            const n = +e.target.value;
            setDraft(n);
            handleChange();
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleChange();
            }
          }}
        ></TextField>
      )}
    </Box>
  );
};

export default NumberInput;

import React, { FC, useRef, useState } from 'react';
import { Avatar, Box, ButtonBase, Link, Menu, MenuItem } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { logout } from 'api/user-manager';

interface ProfileProps {
  openNavBar: boolean;
}

const Profile: FC<ProfileProps> = ({ openNavBar }: ProfileProps) => {
  const history = useHistory();
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleLogout = async (): Promise<void> => {
    try {
      setOpen(false);
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <Box>
      {openNavBar && (
        <>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                sx={{
                  cursor: 'pointer',
                  width: 64,
                  height: 64,
                }}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
        </>
      )}
      <Box mt={2} textAlign="center">
        <Link
          component={ButtonBase}
          onClick={() => setOpen(true)}
          ref={ref}
          variant="h5"
          color="textPrimary"
          underline="none"
        >
          {openNavBar && (
            <>
              {' '}
              {user.name}
              <ArrowDropDownIcon />
            </>
          )}
          {!openNavBar && (
            <Avatar
              alt="User"
              src={user.avatar}
              sx={{
                cursor: 'pointer',
                width: 32,
                height: 32,
              }}
            />
          )}
        </Link>
      </Box>
      <Menu
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        anchorEl={ref.current}
        open={isOpen}
        sx={{
          width: 200,
        }}
      >
        <MenuItem
          component={RouterLink}
          to="/app/account"
          onClick={() => setOpen(false)}
        >
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Profile;

import { useTheme, useMediaQuery, Breakpoint } from '@mui/material';

const useIsMobile = (breakpoint: Breakpoint = 'md'): boolean => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint), {
    noSsr: true,
  });
  return isMobile;
};

export default useIsMobile;

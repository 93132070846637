import React, {
  createContext,
  ReactNode,
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'store';
import { fetchDays } from 'slices/day-slice';
import { fetchTasks } from 'slices/tasks-slice';
import {
  getDateKey,
  getDayMinutes,
  DayModel,
  ScheduleInterval,
  MINUTES_INC,
} from 'features/day/day-types';
import { getTaskInfo } from 'features/day/task-stat';
import { updateDayModel } from 'features/tasks/task-func';
import { playSound, SHIP_BELL_SOUND, DING_SOUND } from 'utils/sounds';
import { getRunningTask, runSession, initSession } from 'features/day/session';
import { ModelEdit } from 'models/model-types';
import { Task } from 'models/task';
import TaskEditModal from 'views/tasks/TaskEditModal';
import runSchedule from 'features/day/schedule';
import rulebook from 'features/rulebook';
import { updateDayItemLog } from 'features/log/day-logs';
import useAuth from 'hooks/useAuth';

interface WorkContextValue {
  currentTime?: number;
  todayModel?: DayModel;
  todaySchedule?: ScheduleInterval[];
  openTaskEdit?: (edit: ModelEdit<Task>) => void;
}

interface WorkProviderProps {
  children: ReactNode;
}

export const WorkProviderWrapper: FC<WorkProviderProps> = ({
  children,
}: WorkProviderProps) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <WorkProvider>{children}</WorkProvider>;
  } else {
    return <>{children}</>;
  }
};

const WorkContext = createContext<WorkContextValue>({});

export const WorkProvider: FC<WorkProviderProps> = ({
  children,
}: WorkProviderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDays(new Date()));
    dispatch(fetchTasks());
  }, [dispatch]);

  const todayModel = useSelector(
    (state) => state.calendar.days[getDateKey(new Date())]
  );

  const [currentTime, setCurrentTime] = useState(getDayMinutes());

  const [taskEdit, setTaskEdit] = useState<ModelEdit<Task> | null>();

  const openTaskEdit = (edit: ModelEdit<Task>) => {
    setTaskEdit(edit);
  };

  const [value, setValue] = useState<WorkContextValue>({ openTaskEdit });

  useEffect(() => {
    const todaySchedule = runSchedule(todayModel, currentTime);
    setValue((v) => ({ ...v, todaySchedule }));
  }, [currentTime, todayModel]);

  useEffect(() => {
    setValue((v) => ({ ...v, todayModel }));
    const intervalId = setInterval(() => {
      const min = getDayMinutes();
      setCurrentTime(min);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [todayModel]);

  useEffect(() => {
    const update = async () => {
      const m = await runSession(todayModel, currentTime);
      updateDayModel(m, currentTime, true);
      setValue((v) => ({ ...v, currentTime }));

      const task = getRunningTask(m);
      const ti = getTaskInfo(task, value.todaySchedule, currentTime);
      const state = m?.session?.item?.state;
      if (ti?.stat && ti.stat.planned === ti.stat.done && state === 'on') {
        playSound(SHIP_BELL_SOUND);
      } else if (
        ti?.stat &&
        ti.stat.planned < ti.stat.done &&
        ti.stat.done <
          ti.stat.planned + MINUTES_INC * rulebook.session.breakDings &&
        state !== 'stop'
      ) {
        playSound(DING_SOUND);
      } else if (task?.id !== todayModel?.session?.item?.id && state === 'on') {
        playSound(DING_SOUND);
      }
      if (task && state === 'on') {
        updateDayItemLog(task.id, todayModel, currentTime);
      }
    };

    update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  useEffect(() => {
    const setFirstTask = async () => {
      if (
        todayModel &&
        !todayModel?.session?.item?.id &&
        todayModel?.tasks?.some((t) => !t.completed)
      ) {
        const m = await initSession(todayModel, currentTime);
        updateDayModel(m, currentTime, true);
      }
    };
    setFirstTask();
  }, [currentTime, todayModel]);

  return (
    <WorkContext.Provider value={value}>
      {children}
      <TaskEditModal edit={taskEdit} />
    </WorkContext.Provider>
  );
};

export default WorkContext;

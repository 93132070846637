import { DayItem, getDayItem, TaskIntentRecord } from 'features/day/day-types';
import { Task } from 'models/task';
import { loadStatRecord, saveStatRecord } from 'api/stat-api';
import { addDays } from 'date-fns';
import { loadLogItemsById } from 'api/log-api';

export type StatRecordType = 'task' | 'day';

export type DayStatRecord = {
  done: number;
  planned: number;
  remaining?: number;
  score: number;
  intent?: TaskIntentRecord[];
};

export type ItemStatRecord = {
  key: string;
  type: StatRecordType;
  item?: DayItem;
  history: Record<string, DayStatRecord>;
};

export const getStatRecordKey = (type: StatRecordType, id?: string) => {
  const key = id ? `${type}-${id}` : type;
  return key;
};

export const getEmptyStatRecord = (
  type: StatRecordType,
  task?: Task
): ItemStatRecord => ({
  key: getStatRecordKey(type, task?.id),
  type,
  item: task ? getDayItem(task) : null,
  history: {},
});

export const createStatRecordFromLogs = async (
  type: StatRecordType,
  task?: Task
) => {
  const stat = await loadStatRecord(task, true);
  const from = addDays(new Date(), -60);
  const logs = await loadLogItemsById(from, task?.id);
  const history = logs
    .filter((l) => l.type === 'day')
    .reduce((acc, l) => {
      acc[l.date] = {
        done: l.stat.done,
        score: l.stat.score,
        planned: l.stat.planned,
      };
      if (l.progress?.estValue && l.progress?.estValue > l.progress?.value) {
        acc[l.date].remaining = l.progress?.estValue - l.progress?.value;
      }
      return acc;
    }, {});
  stat.history = history;
  return saveStatRecord(stat);
};

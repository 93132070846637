import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'store';
import { Task, TaskUpdate } from 'models/task';
import { getTasks, saveTask, deleteTask, updateTask } from 'api/task-api';
import { EntityStoreState } from './slice-types';

interface TasksState extends EntityStoreState {
  activeTasks: Record<string, Task>;
}

const initialState: TasksState = {
  loaded: false,
  loading: false,
  activeTasks: {},
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    getTasksStart(state: TasksState) {
      state.loading = true;
      state.error = null;
    },
    getTasksSuccess(
      state: TasksState,
      action: PayloadAction<{ tasks: Task[] }>
    ) {
      state.activeTasks = action.payload.tasks.reduce((acc, t) => {
        acc[t.id] = t;
        return acc;
      }, {});
      state.loaded = true;
      state.loading = false;
    },
    getTasksFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setTask(state: TasksState, action: PayloadAction<{ task: Task }>) {
      const { task } = action.payload;
      if (state.activeTasks[task.id]) {
        delete state.activeTasks[task.id];
      }
      if (!task.completed) {
        state.activeTasks[task.id] = task;
      }
    },
    updateTasks(state: TasksState, action: PayloadAction<TaskUpdate[]>) {
      action.payload.forEach((u) => {
        if (state.activeTasks[u.taskId]) {
          if (u.data?.completed) {
            delete state.activeTasks[u.taskId];
          } else {
            state.activeTasks[u.taskId] = {
              ...state.activeTasks[u.taskId],
              ...u.data,
            };
          }
        }
      });
    },
    deleteTask(state: TasksState, action: PayloadAction<{ taskId: string }>) {
      if (state.activeTasks[action.payload.taskId]) {
        delete state.activeTasks[action.payload.taskId];
      }
    },
  },
});

export const { reducer } = slice;

export const fetchTasks = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  if (!state.tasks.loaded && !state.tasks.loading) {
    try {
      dispatch(slice.actions.getTasksStart());
      const tasks = await getTasks(true);
      dispatch(slice.actions.getTasksSuccess({ tasks }));
    } catch (err) {
      dispatch(slice.actions.getTasksFailure(err));
    }
  }
};

export const storeTask =
  (t: Task): AppThunk =>
  async (dispatch) => {
    const task = await saveTask(t);
    dispatch(slice.actions.setTask({ task }));
  };

export const storePartialTasks =
  (updates: TaskUpdate[]): AppThunk =>
  async (dispatch) => {
    updates.forEach(async (u) => {
      await updateTask(u.taskId, u.data);
    });
    dispatch(slice.actions.updateTasks(updates));
  };

export const removeTask =
  (t: Task): AppThunk =>
  async (dispatch) => {
    await deleteTask(t.id);
    dispatch(slice.actions.deleteTask({ taskId: t.id }));
  };

export default slice;

import React from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ScrollReset from 'components/ui/ScrollReset';
import { AuthProvider } from 'contexts/FirebaseAuthContext';
import useSettings from 'hooks/useSettings';
import { createAppTheme } from 'theme';
import routes, { renderRoutes } from 'Routes';
import { WorkProviderWrapper } from 'contexts/WorkContext';
import AppGlobalStyles from 'components/app/AppGlobalStyles';
import { APP_VERSION } from 'shared/constants';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends AppTheme {}
// }

const history = createBrowserHistory();

Sentry.init({
  release: 'mydeepwork@' + APP_VERSION,
  dsn: 'https://653def0ee64d46379d0b7a7c4bb1f039@o1080455.ingest.sentry.io/6086344',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
});

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createAppTheme({
    theme: settings.theme,
  });

  return (
    // <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <SnackbarProvider dense maxSnack={3}>
          <Router history={history}>
            <AuthProvider>
              <WorkProviderWrapper>
                <AppGlobalStyles />
                <ScrollReset />
                {/* <SettingsNotification /> */}
                {renderRoutes(routes)}
              </WorkProviderWrapper>
            </AuthProvider>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
    // </StyledEngineProvider>
  );
};

export default App;

import React, { useState } from 'react';
import type { FC } from 'react';

import { HuePicker } from 'react-color';
import { Box } from '@mui/material';

// const PREFIX = 'ColorPicker';

// const classes = {
//   root: `${PREFIX}-root`,
//   color: `${PREFIX}-color`,
//   swatch: `${PREFIX}-swatch`,
//   popover: `${PREFIX}-popover`,
//   cover: `${PREFIX}-cover`,
//   arrow: `${PREFIX}-arrow`,
//   picker: `${PREFIX}-picker`,
//   cell: `${PREFIX}-cell`,
// };

// const Root = styled('div')(() => ({
//   [`&.${classes.root}`]: {},

//   [`& .${classes.color}`]: {
//     width: '36px',
//     height: '14px',
//     borderRadius: '2px',
//   },

//   [`& .${classes.swatch}`]: {
//     padding: '5px',
//     background: '#fff',
//     borderRadius: '1px',
//     boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
//     display: 'inline-block',
//     cursor: 'pointer',
//   },

//   [`& .${classes.popover}`]: {
//     position: 'absolute',
//     zIndex: 2,
//     cursor: 'pointer',
//   },

//   [`& .${classes.cover}`]: {
//     // position: 'fixed',
//     // top: '0px',
//     // right: '0px',
//     // bottom: '0px',
//     // left: '0px',
//   },

//   [`& .${classes.arrow}`]: {
//     position: 'absolute',
//     borderWidth: '7px',
//     borderStyle: 'solid',
//     borderColor: 'transparent transparent rgb(255, 255, 255)',
//     borderImage: 'initial',
//     top: '-14px',
//     left: '10px',
//   },

//   [`& .${classes.picker}`]: {
//     width: '250px',
//     display: 'flex',
//   },

//   [`& .${classes.cell}`]: {
//     width: '25px',
//     height: '25px',
//     fontSize: '0px;',
//     cursor: 'pointer',
//     zIndex: 3,
//   },
// }));

export type ColorPickerProps = {
  color: number;
  onChange: (color: number) => void;
};

const ColorPicker: FC<ColorPickerProps> = ({
  color,
  onChange,
}: ColorPickerProps) => {
  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState<number>(color);

  return (
    <Box
      sx={{
        '& .color': {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
        },

        '& .swatch': {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },

        '& .popover': {
          position: 'absolute',
          zIndex: 2,
          cursor: 'pointer',
        },

        '& .arrow': {
          position: 'absolute',
          borderWidth: '7px',
          borderStyle: 'solid',
          borderColor: 'transparent transparent rgb(255, 255, 255)',
          borderImage: 'initial',
          top: '-14px',
          left: '10px',
        },

        '& .picker': {
          width: '250px',
          display: 'flex',
        },

        '& .cell': {
          width: '25px',
          height: '25px',
          fontSize: '0px;',
          cursor: 'pointer',
          zIndex: 3,
        },
      }}
    >
      <div>
        <div
          className="swatch"
          title={`hue: ${selected}`}
          onClick={() => {
            setShow(!show);
          }}
        >
          <div
            className="color"
            style={{
              backgroundColor: `hsla(${selected}, 60%, 60%)`,
            }}
          />
        </div>
        {show ? (
          <div className="popover">
            <div
              className="cover"
              onClick={() => {
                setShow(false);
              }}
            />
            <HuePicker
              color={{ h: selected, s: 50, l: 50 }}
              // color={`hsla(${color}, 50%, 50%)`}
              onChange={(c) => {
                const h = Math.round(c.hsl.h);
                setSelected(h);
                onChange(h);
              }}
            />
          </div>
        ) : null}
      </div>
    </Box>
  );
};

export default ColorPicker;

import React, { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import type { AppUser } from 'models/user';
import SplashScreen from 'components/app/SplashScreen';
import 'firebase/auth';
import { loadAppUser } from 'api/user-manager';
import UserContext from 'features/user/user-context';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebaseApp from 'lib/firebase-app';
// import axios from 'utils/axios';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user?: AppUser;
}

interface AuthContextValue extends AuthState {
  refreshUser: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction =
  | {
      type: 'AUTH_STATE_CHANGED';
      payload: {
        isAuthenticated: boolean;
        user?: AppUser;
      };
    }
  | {
      type: 'USER_CHANGED';
      payload: {
        user: AppUser;
      };
    };

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'USER_CHANGED': {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  refreshUser: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const refreshUser = () => {
    dispatch({
      type: 'USER_CHANGED',
      payload: {
        user: UserContext.getUser(),
      },
    });
  };

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const appUser = await loadAppUser();
          // const idToken = await user.getIdToken();
          // await axios.post('/auth/session', { idToken });
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: appUser,
            },
          });
        } catch (e) {
          console.error('auth', e);
          throw e;
        }
      } else {
        UserContext.setUser(null, false);
        // await axios.post('/auth/session', { idToken: null });
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

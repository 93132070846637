import { query, where, getDocs } from 'firebase/firestore';
import { Task } from 'models/task';
import {
  fsCollection,
  fsDeleteDoc,
  fsGetDoc,
  fsSetDoc,
  fsUpdateDoc,
} from './firestore-collection';

export const saveTask = async (task: Task) => {
  task.updated_at = new Date().toJSON();
  try {
    await fsSetDoc('tasks', task.id, task);
    return task;
  } catch (e) {
    console.error('saveTask', e);
    throw e;
  }
};

export const updateTask = async (taskId: string, updateData: Partial<Task>) => {
  try {
    await fsUpdateDoc('tasks', taskId, updateData);
  } catch (e) {
    console.error('updateTask', e);
    throw e;
  }
};

export const deleteTask = async (taskId: string) => {
  try {
    await fsDeleteDoc('tasks', taskId);
  } catch (e) {
    console.error('saveTask', e);
    throw e;
  }
};

export const getTask = async (id) => {
  try {
    const taskDoc = await fsGetDoc('tasks', id);
    return taskDoc.exists() ? (taskDoc.data() as Task) : null;
  } catch (e) {
    console.error('getTask', e);
    throw e;
  }
};

export const getTasks = async (active: boolean) => {
  try {
    let q = query(fsCollection('tasks'));
    if (active) {
      q = query(q, where('completed', '!=', true));
    }
    const data = await getDocs(q);

    return data.docs.map((t) => t.data() as Task);
  } catch (e) {
    console.error('getTasks', e);
    throw e;
  }
};

import React from 'react';
import type { FC, ReactNode } from 'react';

import { Box } from '@mui/material';
import { CONTROL_BAR } from 'shared/constants';
import NavBar from './NavBar';
import FooterBar from './FooterBar';
import useSettings from 'hooks/useSettings';

interface DashboardLayoutProps {
  children?: ReactNode;
  scroll?: boolean;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({
  children,
  scroll,
}: DashboardLayoutProps) => {
  const { settings, saveSettings } = useSettings();

  return (
    <Box
      sx={{
        backgroundColor: 'background.dark',
        display: 'flex',
        height: '100%',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'auto',
      }}
    >
      <NavBar
        onNavBarChange={() => {
          saveSettings({ ...settings, navBarClosed: !settings.navBarClosed });
        }}
        openNavBar={!settings.navBarClosed}
      />
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          minWidth: 256,
          paddingBottom: [
            `${CONTROL_BAR.bottomSm}px`,
            `${CONTROL_BAR.bottom}px`,
          ],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              height: '100%',
              width: '100%',
              overflow: scroll !== false ? 'auto' : undefined,
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      <FooterBar openNavBar={!settings.navBarClosed} />
    </Box>
  );
};

export const FixedDashboardLayout: FC<DashboardLayoutProps> = ({
  children,
}: DashboardLayoutProps) => (
  <DashboardLayout scroll={false}>{children}</DashboardLayout>
);

export default DashboardLayout;

import { onlyUnique } from './lists';

export const getRandomColor = (exclude: number[]) => {
  let unique = exclude.filter(onlyUnique);
  if (unique.length > 360) {
    unique = [];
  }
  while (true) {
    const color = Math.floor(Math.random() * 360);
    if (!unique.some((c) => c === color)) {
      return color;
    }
  }
};

export default getRandomColor;

import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { logout } from 'api/user-manager';

interface AdminGuardProps {
  children?: ReactNode;
}

const AdminGuard: FC<AdminGuardProps> = ({ children }: AdminGuardProps) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated || !user) {
    logout();
    return <Redirect to="/login" />;
  }

  if (user?.admin !== true) {
    return <Redirect to="/app/dashboard" />;
  }

  return <>{children}</>;
};

export default AdminGuard;

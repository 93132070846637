import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'store';
import { DayModel, getDateKey, emptyDay } from 'features/day/day-types';
import { addDays, eachDayOfInterval } from 'date-fns';
import { loadDayModelsByDate, saveDayModel } from 'api/day-api';
import { toDate } from 'features/day/day-layer';
import { EntityStoreState } from './slice-types';

interface DaysState extends EntityStoreState {
  days: Record<string, DayModel>;
}

const initialState: DaysState = {
  loaded: false,
  loading: false,
  days: {},
};

const slice = createSlice({
  name: 'days',
  initialState,
  reducers: {
    getDaysStart(state: DaysState) {
      state.loading = true;
      state.error = null;
    },
    getDaysSuccess(
      state: DaysState,
      action: PayloadAction<{ days: DayModel[] }>
    ) {
      state.days = action.payload.days.reduce((a, m) => {
        a[m.date] = m;
        return a;
      }, state.days);
      state.loaded = true;
      state.loading = false;
    },
    getDaysFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setDay(state: DaysState, action: PayloadAction<{ day: DayModel }>) {
      const { day } = action.payload;
      state.days[day.date] = day;
    },
  },
});

export const { reducer } = slice;

export const fetchDays =
  (start: Date, end?: Date, preload = 2): AppThunk =>
  async (dispatch, getState) => {
    const { calendar } = getState();

    if (calendar.loading) {
      return;
    }

    start = addDays(start, -preload);
    end = addDays(end ?? start, preload);
    const missingDates = eachDayOfInterval({ start, end })
      .map((d) => getDateKey(d))
      .filter((d) => !calendar.days[d]);

    if (missingDates.length > 0) {
      try {
        dispatch(slice.actions.getDaysStart());
        const newDays = await loadDayModelsByDate(missingDates);
        const days = missingDates.map((md) => {
          const day = newDays.find((d) => d.date === md);
          return day || emptyDay(toDate(md));
        });
        dispatch(
          slice.actions.getDaysSuccess({
            days,
          })
        );
      } catch (err) {
        dispatch(slice.actions.getDaysFailure(err));
      }
    }
  };

export const storeDay =
  (d: DayModel): AppThunk =>
  async (dispatch) => {
    const day = await saveDayModel(d);
    dispatch(slice.actions.setDay({ day }));
  };

export default slice;

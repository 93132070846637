import {
  DayModel,
  ScheduleInterval,
  getDayItemKey,
  normalizeTime,
  getDayItem,
} from './day-types';
import { getAllTaskStat } from './task-stat';

export const runSchedule = (model: DayModel, currentTime: number) => {
  if (!model?.tasks || !model?.plan) {
    return [];
  }

  const { plan } = model;
  const schedule: ScheduleInterval[] = [...plan];

  const stat = getAllTaskStat(model, schedule, currentTime);

  while (true) {
    const task = model.tasks.find(
      (t) => stat.get(getDayItemKey(t))?.available > 0 && !t.completed
    );
    const index = schedule.findIndex(
      (i) => i.state === 'on' && !i.id && i.end > i.start && i.end > currentTime
    );
    if (!task || index < 0) {
      break;
    }

    const interval = schedule[index];
    const start = Math.max(interval.start, currentTime);

    const id = getDayItemKey(task);
    const ts = stat.get(id);
    const minutes = Math.min(ts.available, interval.end - start);
    const br = Math.max(
      0,
      Math.min(normalizeTime(task.break), interval.end - start - minutes)
    );

    stat.set(id, { ...ts, available: ts.available - minutes });

    const newinterval = {
      ...interval,
      ...getDayItem(task),
      start,
      end: start + minutes,
      flow: true,
    };

    const added = [];
    if (interval.start < start) {
      added.push({
        ...interval,
        end: start,
      });
    }
    added.push(newinterval);
    if (br > 0) {
      added.push({
        ...interval,
        start: start + minutes,
        end: start + minutes + br,
        state: 'break',
      });
    }
    if (start + minutes + br < interval.end) {
      added.push({
        ...interval,
        start: start + minutes + br,
      });
    }
    schedule.splice(index, 1, ...added);
  }

  return schedule;
};

export default runSchedule;

/* eslint-disable react/display-name */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout, { FixedDashboardLayout } from 'layouts/DashboardLayout';
import MainLayout from 'layouts/MainLayout';
import HomeView from 'views/home/HomeView';
import LoadingScreen from 'components/app/LoadingScreen';
import AuthGuard from 'components/app/AuthGuard';
import GuestGuard from 'components/app/GuestGuard';
import AdminGuard from 'components/app/AdminGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    layout: MainLayout,
    component: lazy(() => import('views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/loading',
    layout: MainLayout,
    component: lazy(() => import('components/app/SplashScreen')),
  },
  {
    exact: true,
    path: '/terms',
    layout: MainLayout,
    component: lazy(() => import('views/home/TermsView')),
  },
  {
    exact: true,
    path: '/privacy',
    layout: MainLayout,
    component: lazy(() => import('views/home/PrivacyView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('views/auth/LoginView')),
  },
  {
    exact: true,
    guard: AdminGuard,
    path: '/admin-login',
    component: lazy(() => import('views/auth/AdminLoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup',
    component: lazy(() => import('views/auth/RegisterView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('views/auth/ForgotPasswordView')),
  },
  {
    path: '/app/day/:day?',
    guard: AuthGuard,
    layout: FixedDashboardLayout,
    //exact: true,

    component: lazy(() => import('views/DayView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('views/dashboard/DashboardView')),
      },
      {
        exact: true,
        path: '/app/day/:day?',
        component: lazy(() => import('views/DayView')),
      },
      {
        exact: true,
        path: '/app/tasks',
        component: lazy(() => import('views/tasks/TaskList')),
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;

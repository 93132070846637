import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'store';
import {
  ItemStatRecord,
  DayStatRecord,
  getEmptyStatRecord,
} from 'features/stat/stat';
import {
  loadTaskStatRecords,
  saveStatRecord,
  saveStatRecordDay,
  loadStatRecord,
} from 'api/stat-api';
import { Task } from 'models/task';
import { EntityStoreState } from './slice-types';

interface StatRecordsState extends EntityStoreState {
  taskStatRecords: Record<string, ItemStatRecord>;
  dayRecord: ItemStatRecord;

  taskLoading: Record<string, boolean>;
}

const initialState: StatRecordsState = {
  loaded: false,
  loading: false,
  taskLoading: {},
  taskStatRecords: {},
  dayRecord: getEmptyStatRecord('day'),
};

const slice = createSlice({
  name: 'stat',
  initialState,
  reducers: {
    getStatStart(state: StatRecordsState, action: PayloadAction<string[]>) {
      state.taskLoading = action.payload.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        { ...state.taskLoading }
      );
      state.error = null;
    },
    getStatSuccess(
      state: StatRecordsState,
      action: PayloadAction<{
        dayStat?: ItemStatRecord;
        taskStat?: ItemStatRecord[];
      }>
    ) {
      const { dayStat, taskStat } = action.payload;
      if (taskStat) {
        state.taskStatRecords = taskStat.reduce(
          (acc, r) => {
            acc[r.item.id] = r;
            return acc;
          },
          { ...state.taskStatRecords }
        );
        state.taskLoading = taskStat.reduce(
          (acc, r) => {
            acc[r.item.id] = false;
            return acc;
          },
          { ...state.taskLoading }
        );
      }
      if (dayStat) {
        state.dayRecord = dayStat;
      }
      state.loaded = true;
    },
    getStatFailure(state, action: PayloadAction<string>) {
      state.taskLoading = {};
      state.error = action.payload;
    },
    setStat(state: StatRecordsState, action: PayloadAction<ItemStatRecord>) {
      if (action.payload?.item?.id) {
        const result = { ...state.taskStatRecords };
        result[action.payload.item.id] = action.payload;
        state.taskStatRecords = result;
      } else {
        state.dayRecord = { ...action.payload };
      }
    },
    setDayStat(
      state: StatRecordsState,
      action: PayloadAction<{
        id: string;
        date: string;
        record: DayStatRecord;
      }>
    ) {
      const { id, date, record } = action.payload;
      if (id) {
        const result = { ...state.taskStatRecords };
        result[id].history[date] = record;
        state.taskStatRecords = result;
      } else {
        const result = { ...state.dayRecord };
        result.history[date] = record;
        state.dayRecord = result;
      }
    },
  },
});

export const { reducer } = slice;

export const fetchTaskStat =
  (taskIds: string[]): AppThunk =>
  async (dispatch, getState) => {
    const { stat } = getState();

    try {
      let dayStat = null;
      let taskStat = null;

      if (!stat.loaded) {
        dayStat = await loadStatRecord();
      }

      const missingIds = taskIds.filter(
        (id) => !stat.taskStatRecords[id] && !stat.taskLoading[id]
      );
      if (missingIds.length > 0) {
        dispatch(slice.actions.getStatStart(missingIds));
        taskStat = await loadTaskStatRecords(missingIds);
      }
      if (dayStat || taskStat) {
        dispatch(slice.actions.getStatSuccess({ dayStat, taskStat }));
      }
    } catch (err) {
      dispatch(slice.actions.getStatFailure(err));
    }
  };

export const storeStat =
  (s: ItemStatRecord): AppThunk =>
  async (dispatch) => {
    const stat = await saveStatRecord(s);
    dispatch(slice.actions.setStat(stat));
  };

export const storeStatDay =
  (date: string, record: DayStatRecord, task?: Task): AppThunk =>
  async (dispatch, getState) => {
    const { stat } = getState();
    if (
      (task && stat.taskStatRecords[task.id]) ||
      (!task && Object.keys(stat.dayRecord?.history).length > 0)
    ) {
      await saveStatRecordDay(task?.id, date, record);
      dispatch(slice.actions.setDayStat({ id: task?.id, date, record }));
    } else {
      const ts = await loadStatRecord(task);
      ts.history[date] = record;
      const s = await saveStatRecord(ts);
      dispatch(slice.actions.setStat(s));
    }
  };

export default slice;

import { TaskInfo, Task, TaskStat, getScore } from 'models/task';
import {
  MINUTES_INC,
  DayModel,
  DayInterval,
  ScheduleInterval,
  getDayItemKey,
  normalizeTime,
} from './day-types';

export const getTaskInfo = (
  task: Task,
  schedule: DayInterval[],
  currentTime: number
): TaskInfo => {
  if (!task || !schedule) {
    return null;
  }
  const stat = getTaskStat(task, schedule, currentTime);
  return { task, stat };
};

export const calculateTaskStat = (
  task: Task,
  taskSchedule: ScheduleInterval[],
  totalPlanned: number,
  currentTime: number
): TaskStat => {
  const done = taskSchedule
    .filter((i) => i.start < currentTime)
    .reduce((sum, i) => sum + Math.min(i.end, currentTime) - i.start, 0);
  const current = taskSchedule.some(
    (i) => i.start <= currentTime && currentTime < i.end && !i.flow
  )
    ? MINUTES_INC
    : 0;
  const scheduled = taskSchedule
    .filter((i) => i.end > currentTime + MINUTES_INC && !i.flow)
    .reduce(
      (sum, i) => sum + i.end - Math.max(currentTime + MINUTES_INC, i.start),
      0
    );
  const planned = Math.max(normalizeTime(totalPlanned), scheduled);
  const total = taskSchedule.reduce((sum, i) => sum + i.end - i.start, 0);
  const start = taskSchedule.length > 0 ? taskSchedule[0].start : null;
  const end =
    taskSchedule.length > 0 ? taskSchedule[taskSchedule.length - 1].end : null;
  const remaining =
    scheduled > 0
      ? Math.max(planned - done, scheduled) - current
      : planned - done - current;
  return {
    planned,
    done,
    remaining,
    available: planned - done - scheduled - current,
    scheduled,
    delta: total - planned,
    start,
    end,
    totalScore: getScore(total - current, task),
    score: getScore(done, task),
    count: taskSchedule.length,
    countDone: taskSchedule.filter((i) => i.end <= currentTime).length,
  };
};

export const getTaskStat = (
  task: Task,
  schedule: ScheduleInterval[],
  currentTime: number
): TaskStat => {
  const taskSchedule = schedule.filter(
    (i) => getDayItemKey(i) === getDayItemKey(task) && i.state === 'on'
  );
  return calculateTaskStat(task, taskSchedule, task.minutes, currentTime);
};

export const getFullTaskStat = (
  task: Task,
  model: DayModel,
  schedule?: ScheduleInterval[],
  currentTime?: number
): TaskStat => {
  const taskSchedule = (schedule ?? model.plan ?? []).filter(
    (i) => i.id === task.id && i.state === 'on'
  );
  const planned = model.tasks
    .filter((t) => t.id === task.id)
    .reduce((sum, t) => sum + t.minutes, 0);
  return calculateTaskStat(task, taskSchedule, planned, currentTime ?? 24 * 60);
};

export const getAllTaskStat = (
  model: DayModel,
  schedule: ScheduleInterval[],
  currentTime: number
): Map<string, TaskStat> => {
  if (!model.tasks || !model.plan) {
    return new Map();
  }
  const map = new Map(
    model.tasks
      .filter((t) => t.minutes > 0)
      .map((t): [string, TaskStat] => {
        const stat = getTaskStat(t, schedule, currentTime);
        return [getDayItemKey(t), stat];
      })
  );
  return map;
};

export const getDayStat = (
  model: DayModel,
  schedule: DayInterval[],
  currentTime: number
): TaskStat => {
  if (!model.tasks?.length || !model.plan?.length) {
    return null;
  }

  schedule = schedule || model.plan;

  const map = getAllTaskStat(model, schedule, currentTime);

  const tasks = model.tasks.filter((t) => map.has(getDayItemKey(t)));

  const remaining = tasks
    .filter((t) => !t.completed)
    .reduce(
      (sum, t) => sum + Math.max(0, map.get(getDayItemKey(t))?.remaining),
      0
    );

  const planned = tasks.reduce(
    (sum, t) => sum + map.get(getDayItemKey(t))?.planned,
    0
  );

  const done = tasks.reduce(
    (sum, t) => sum + map.get(getDayItemKey(t))?.done,
    0
  );

  const score = tasks.reduce(
    (sum, t) => sum + map.get(getDayItemKey(t))?.score,
    0
  );

  const totalScore = tasks.reduce(
    (sum, t) => sum + map.get(getDayItemKey(t))?.totalScore,
    0
  );

  const breaks = schedule
    .filter(
      (i, n) =>
        i.state === 'break' && i.end > currentTime && n < schedule.length - 1
    )
    .reduce(
      (sum, i) => sum + i.end - Math.max(currentTime + MINUTES_INC, i.start),
      0
    );

  const available = model.plan
    .filter((i) => i.state === 'on' && i.end > currentTime && !i.id)
    .reduce(
      (sum, i) => sum + i.end - Math.max(currentTime + MINUTES_INC, i.start),
      -breaks
    );

  const scheduled = model.plan
    .filter(
      (i) => i.end > currentTime + MINUTES_INC && i.id && i.state === 'on'
    )
    .reduce(
      (sum, i) => sum + i.end - Math.max(currentTime + MINUTES_INC, i.start),
      0
    );

  return {
    planned,
    done,
    score,
    totalScore,
    remaining,
    available,
    delta: available + scheduled - remaining,
    scheduled,
    count: tasks.length,
    countDone: tasks.filter((t) => t.completed).length,
  };
};

import React from 'react';
import type { FC } from 'react';
import { Avatar, Box, Container, Grid, Typography } from '@mui/material';

// const PREFIX = 'Features';

// const classes = {
//   root: `${PREFIX}-root`,
//   avatar: `${PREFIX}-avatar`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.default,
//     paddingTop: 100,
//     paddingBottom: 100,
//   },

//   [`& .${classes.avatar}`]: {
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.secondary.contrastText,
//   },
// }));

interface FeaturesProps {
  className?: string;
}

const Features: FC<FeaturesProps> = ({ className, ...rest }: FeaturesProps) => (
  <Box
    className={className}
    sx={{
      backgroundColor: 'background.default',
      pt: 12,
      pb: 12,
    }}
    {...rest}
  >
    <Container maxWidth="lg">
      <Typography
        component="p"
        variant="overline"
        color="secondary"
        align="center"
      >
        Explore My Deep Work
      </Typography>
      <Typography variant="h1" align="center" color="textPrimary">
        Not just a task manager
      </Typography>
      <Box
        mt={8}
        sx={{
          '& .items': {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box display="flex">
              <Avatar className="items">01</Avatar>
              <Box ml={2}>
                <Typography variant="h4" gutterBottom color="textPrimary">
                  Master your work day
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Choose tasks that you want to do and get a day plan that
                  updates every minute.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex">
              <Avatar className="items">02</Avatar>
              <Box ml={2}>
                <Typography variant="h4" gutterBottom color="textPrimary">
                  Focus on most important
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Analytics for your work to understand what is possible and how
                  to improve.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex">
              <Avatar className="items">03</Avatar>
              <Box ml={2}>
                <Typography variant="h4" gutterBottom color="textPrimary">
                  Make things happen
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  Tactical and strategic execution system for your tasks,
                  projects and goals.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Box>
);

export default Features;

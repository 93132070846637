import { DayItemLog } from 'features/log/day-logs';
import { getDateKey } from 'features/day/day-types';
import { fsCollection, fsSetDoc, fsGetDoc } from './firestore-collection';
import { getDocs, orderBy, query, where } from 'firebase/firestore';

export const saveLogItem = async (log: DayItemLog) => {
  try {
    await fsSetDoc('logs', log.key, log);
    return log;
  } catch (e) {
    console.error('saveLogItem', e);
    throw e;
  }
};

export const loadItemLog = async (key: string): Promise<DayItemLog> => {
  try {
    const doc = await fsGetDoc('logs', key);
    if (doc.exists()) {
      return doc.data() as DayItemLog;
    }
    return null;
  } catch (e) {
    console.error(`loadItemLog:${key}`, e);
    throw e;
  }
};

export const loadLogItemsById = async (
  from: Date,
  taskId?: string
): Promise<Array<DayItemLog>> => {
  try {
    let q = query(
      fsCollection('logs'),
      where('date', '>=', getDateKey(from)),
      orderBy('date'),
      orderBy('time')
    );
    if (taskId) {
      q = query(q, where('item.id', '==', taskId));
    } else {
      q = query(q, where('item', '==', null));
    }
    const data = await getDocs(q);

    return data.docs.map((d) => d.data() as DayItemLog);
  } catch (e) {
    console.error(`loadLogItemsById:${taskId}`, e);
    throw e;
  }
};

import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import ControlBar from 'components/tasks/ControlBar';
import { AppTheme } from 'theme';

interface FooterBarProps {
  openNavBar: boolean;
}

const FooterBar: FC<FooterBarProps> = () => {
  const theme = useTheme() as AppTheme;
  return (
    <Box
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        height: { xs: theme.frame.bottomSm, sm: theme.frame.bottom },
        backgroundColor: grey[200],
      }}
    >
      <ControlBar />
    </Box>
  );
};
export default FooterBar;

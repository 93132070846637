import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notifications } from 'slices/notification';
import { reducer as tasks } from 'slices/tasks-slice';
import { reducer as calendar } from 'slices/day-slice';
import { reducer as stat } from 'slices/stat-slice';

export const appReducer = combineReducers({
  notifications,
  tasks,
  calendar,
  stat,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

import {
  isSameDay,
  parseISO,
  startOfToday,
  isBefore,
  isAfter,
  endOfToday,
} from 'date-fns';
import rulebook from 'features/rulebook';
import {
  DayLayer,
  DayItem,
  DayInterval,
  MINUTES_INC,
  DayModel,
  getDayItemKey,
} from './day-types';

export const getInterval = (layer: DayLayer, time: number) =>
  layer.model.plan.find((i) => time >= i.start && time < i.end);

export const getScheduleItem = (layer: DayLayer, time: number) =>
  layer.schedule.find((i) => time >= i.start && time < i.end);

export const getLabel = (layer: DayLayer, time: number) => {
  if (!layer.schedule) {
    return undefined;
  }
  const index = layer.schedule.findIndex(
    (i) => i.start <= time && time < i.end
  );
  const interval = index >= 0 ? layer.schedule[index] : null;
  if (interval?.label) {
    const isSame =
      index > 0 && layer.schedule[index - 1].label === interval.label;
    if (!isSame) {
      const ih = interval.start === Math.floor(interval.start / 60) * 60;
      const beginning =
        (interval.start === time && !ih) ||
        (interval.start + MINUTES_INC === time && ih);
      let l = (interval.end - time) / MINUTES_INC;
      l = l > 1 ? l * 6 : l * 4;
      if (beginning && l > 0) {
        return {
          key:
            l < interval.label.length
              ? `${interval.label.substr(0, l)}...`
              : interval.label,
          title: interval.label,
        };
      }
    }
    return { title: interval.label };
  }
  return undefined;
};

const isSameInterval = (i1: DayItem, i2: DayItem) =>
  (getDayItemKey(i1) === getDayItemKey(i2) || (!i1.id && !i2.id)) &&
  (i1.state === i2.state || (!i1.state && !i2.state));

export const insertPlanInterval = (
  plan: DayInterval[],
  item: DayItem,
  start: number,
  end: number
) => {
  item = item || {};
  const before = plan.filter((i) => i.start < start).map((i) => ({ ...i }));
  let after = plan.filter((i) => i.end > end).map((i) => ({ ...i }));
  let merged = false;

  const prev = before.find((i) => i.end >= start);
  if (prev) {
    if (isSameInterval(item, prev)) {
      prev.end = end;
      merged = true;
    } else {
      prev.end = start;
    }
  }

  const next = after.find((i) => i.start <= end);
  if (next) {
    if (isSameInterval(item, next)) {
      if (merged) {
        prev.end = next.end;
        after = after.filter((i) => i.start >= prev.end);
      } else {
        next.start = start;
        merged = true;
      }
    } else {
      next.start = end;
    }
  }

  const current = [];
  if (!merged) {
    const newInterval: DayInterval = {
      start,
      end,
    };
    if (item.id) {
      newInterval.id = item.id;
      newInterval.state = item.state || 'on';
    } else if (item.state) {
      newInterval.state = item.state;
    }
    if (item.color !== undefined) {
      newInterval.color = item.color;
    }
    if (item.label) {
      newInterval.label = item.label;
    }
    if (item.split) {
      newInterval.split = item.split;
    }
    current.push(newInterval);
  }

  const intervals = [...before, ...current, ...after].filter(
    (i) => i.start < i.end && (i.state || i.id || i.label)
  );
  const l = intervals.length;
  if (l > 0 && !intervals[l - 1].state && !intervals[l - 1].id) {
    intervals.splice(l - 1, 1);
  }
  return intervals;
};

export const insertDayInterval = (
  model: DayModel,
  item: DayItem,
  start: number,
  end: number
): DayModel => {
  const plan = insertPlanInterval(model.plan || [], item, start, end);
  return { ...model, plan };
};

export const selectTime = (layer: DayLayer, time: number): DayLayer => {
  if (layer.selected !== null && layer.selected !== undefined) {
    const a = Math.min(time, layer.selected);
    const b = Math.max(time, layer.selected) + MINUTES_INC;

    let item = layer.item || {};

    const first =
      layer.selected != null
        ? layer.model.plan?.find(
            (i) => i.start <= layer.selected && layer.selected < i.end
          )
        : null;

    if (!item.id || first?.id === item.id) {
      item = { state: !first?.state || !!item.id ? 'on' : null };
    }

    const model = insertDayInterval(layer.model, item, a, b);
    return { ...layer, model, selected: null };
  }
  return {
    ...layer,
    selected: time,
  };
};

export const toDate = (date: string) => parseISO(date);

export const isSameHourTime = (t1: number, t2: number) =>
  Math.floor(t1 / 60) === Math.floor(t2 / 60);

export const isToday = (date: string) =>
  isSameDay(toDate(date), startOfToday());

export const isTodayModel = (model: DayModel) =>
  model && isSameDay(toDate(model.date), startOfToday());

export const isPastModel = (model: DayModel) =>
  model && isBefore(toDate(model.date), startOfToday());

export const isFutureModel = (model: DayModel) =>
  model && isAfter(toDate(model.date), endOfToday());

export const getCurrentTime = (model: DayModel, currentTime: number) => {
  const t = isTodayModel(model) ? currentTime : 0;
  return isPastModel(model) ? 24 * 60 : t;
};

export const getExactTime = () => {
  const d = new Date();
  return d.getHours() * 60 + d.getMinutes();
};

export const runDayModel = (
  model: DayModel,
  currentTime?: number,
  exactTime?: number
) => {
  if (isTodayModel(model) && model.session) {
    let item = model.session.item || {};

    let start = currentTime;
    if (model.session.started && item.state === 'on') {
      const prev =
        model?.plan
          ?.filter(
            (i) =>
              i.start >= model.session.started &&
              i.start < currentTime &&
              i.id &&
              getDayItemKey(item) !== getDayItemKey(i)
          )
          .map((i) => i.end) || [];
      start = Math.min(currentTime, Math.max(model.session.started, ...prev));
    }

    const lateStart =
      start === currentTime &&
      exactTime &&
      exactTime >=
        currentTime + MINUTES_INC - rulebook.session.minBlockDuration;
    if (!lateStart) {
      if (item.state === 'break' || item.state === 'stop') {
        const current = model?.plan?.find(
          (i) => i.start <= start && start < i.end
        );
        if (item.id !== current?.id) {
          item = current;
        }
      }

      const m = insertDayInterval(
        model,
        item,
        start,
        currentTime + MINUTES_INC
      );
      return m;
    }
  }
  return model;
};

export const clearDayModel = (model: DayModel) => {
  const plan =
    model.plan?.filter(
      (i) =>
        !i.id || model.tasks?.some((t) => getDayItemKey(t) === getDayItemKey(i))
    ) || [];
  return { ...model, plan };
};

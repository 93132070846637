import React from 'react';
import type { FC } from 'react';
import { Box, Dialog, Typography, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'store';
import { Task } from 'models/task';
import { ModelAction, ModelEdit } from 'models/model-types';
import { useSnackbar } from 'notistack';
import { removeTask, storeTask } from 'slices/tasks-slice';
import TaskForm from './TaskForm';
import { logAppEvent } from 'api/analytics';

type TaskEditModalProps = {
  className?: string;
  edit?: ModelEdit<Task>;
};

const TaskEditModal: FC<TaskEditModalProps> = ({
  edit,
  className,
  ...rest
}: TaskEditModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleAction = async (action: ModelAction<Task>): Promise<void> => {
    if (action.model) {
      logAppEvent('task_edit_' + action.type, {
        desc: action.model.content,
        category: 'task',
      });
      try {
        if (
          !action.model.clone ||
          action.type === 'create' ||
          action.type === 'delete'
        ) {
          if (action.type === 'delete') {
            await dispatch(removeTask(action.model));
            enqueueSnackbar('Task Deleted', {
              variant: 'success',
            });
          }
          if (action.type === 'create' || action.type === 'update') {
            await dispatch(storeTask(action.model));
            enqueueSnackbar('Task Saved', {
              variant: 'success',
            });
          }
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
      }
    }
    if (action.model.clone || action.type !== 'update') {
      edit.onAction(action);
      if (action.type === 'update') {
        enqueueSnackbar('Task Updated', {
          variant: 'success',
        });
      }
    } else {
      edit.onAction(null);
    }
  };

  return (
    <Dialog
      onClose={() => edit.onAction({ type: 'cancel', model: edit.model })}
      open={!!edit}
      maxWidth="md"
      fullWidth
      {...rest}
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ pt: 2, pl: 2 }}
          >
            {edit?.model.clone ? 'Day Task' : 'Task in DB'}
          </Typography>
          <IconButton
            onClick={() => edit.onAction({ type: 'cancel', model: edit.model })}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {edit && (
          <TaskForm
            task={edit.model}
            onAction={handleAction}
            isNew={edit.isNew}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default TaskEditModal;

import store from 'store';
import { Task, TaskUpdate } from 'models/task';
import { getRandomColor } from 'utils/labels';
import { storePartialTasks, storeTask } from 'slices/tasks-slice';
import {
  DayModel,
  getDayItemKey,
  normalizeTime,
  // ScheduleInterval,
  getDayItem,
  getDateKey,
} from 'features/day/day-types';
import { storeDay } from 'slices/day-slice';
import {
  runDayModel,
  getExactTime,
  isTodayModel,
  isPastModel,
  getCurrentTime,
} from 'features/day/day-layer';
import { ModelAction } from 'models/model-types';
import rulebook from 'features/rulebook';
import { updateDayItemLog } from 'features/log/day-logs';
import { logAppEvent } from 'api/analytics';

export const initTask = async (task: Task, tasks: Task[], sync = true) => {
  const exclude = tasks.map((t) => t.color);
  const data: Pick<Task, 'color' | 'boost'> = {};
  if (task.color === undefined) {
    data.color = getRandomColor(exclude);
  }
  if (task.boost === undefined) {
    data.boost = 1;
  }
  if (Object.keys(data).length > 0 && sync) {
    await store.dispatch(storePartialTasks([{ taskId: task.id, data }]));
  }
  return { ...task, ...data };
};

const prepareTaskPartial = (
  taskId: string,
  partial: Partial<Task>,
  date?: string
) => {
  if (partial.progress) {
    date = date ?? getDateKey();
    const dbTask = store.getState().tasks?.activeTasks[taskId];
    if (date && dbTask?.progress?.updated > date && date < getDateKey()) {
      const { progress, ...p } = partial;
      partial = p;
    } else {
      partial.progress = { ...partial.progress, updated: date };
    }
  }
  return partial;
};

export const updateTaskPartial = async (
  task: Task,
  tasks: Task[],
  partial: Partial<Task>,
  persist?: boolean,
  date?: string
) => {
  const index = tasks.findIndex(
    (t) => getDayItemKey(t) === getDayItemKey(task)
  );
  const list = [...tasks];
  list[index] = { ...task, ...partial };
  if (persist) {
    if (task.clone) {
      const { completed, ...p } = partial;
      partial = p;
    }
    if (task.local) {
      await store.dispatch(storeTask(task));
      list[index].local = false;
    } else {
      updateTaskPartials([{ taskId: task.id, data: partial }], date);
    }
  }
  return list;
};

export const updateTaskPartials = async (
  updates: TaskUpdate[],
  date?: string
) => {
  updates.forEach((u) => {
    u.data = prepareTaskPartial(u.taskId, u.data, date);
  });
  updates = updates.filter((u) => Object.keys(u.data).length > 0);
  if (updates.length > 0) {
    await store.dispatch(storePartialTasks(updates));
  }
};

const updateDayLogs = async (
  model: DayModel,
  currentTime: number
  // schedule?: ScheduleInterval[]
) => {
  if (
    (isTodayModel(model) || isPastModel(model)) &&
    model.tasks &&
    model.plan
  ) {
    // schedule = isPastModel(model) ? model.plan : schedule ?? model.plan;
    currentTime = getCurrentTime(model, currentTime);

    const intent = model.intent ? [...model.intent] : [];
    const taskIds = [...Array.from(new Set(model.tasks.map((t) => t.id)))];
    const partialIds = [];
    const doneIds = [];
    taskIds.forEach((id) => {
      const tasks = model.tasks.filter((t) => t.id === id);
      const planned = tasks
        .filter((t) => t.id === id)
        .reduce((sum, t) => sum + t.minutes, 0);
      const done = model.plan
        .filter((i) => i.id === id && i.start < currentTime)
        .reduce((sum, i) => sum + Math.min(i.end, currentTime) - i.start, 0);
      const index = intent.findIndex((i) => i.item.id === id);
      if (
        index < 0 ||
        intent[index].record?.planned !== planned ||
        intent[index].record?.done !== done
      ) {
        const record = { planned, done, time: currentTime };
        if (index >= 0) {
          const current = intent[index];
          intent[index] = { ...current, history: [...current.history], record };
          if (current.record?.planned !== planned) {
            partialIds.push(id);
            intent[index].history.push(record);
          }
          if (current.record?.done !== done) {
            doneIds.push(id);
          }
        } else {
          intent.push({
            item: getDayItem(tasks[0], null, false),
            record,
            history: [record],
          });
          partialIds.push(id);
          doneIds.push(id);
        }
      }
    });

    const m = { ...model, intent };
    partialIds.forEach(async (id) => {
      await updateDayItemLog(id, m, currentTime, 'partial');
    });
    doneIds.forEach(async (id) => {
      await updateDayItemLog(id, m, currentTime);
    });
    if (partialIds.length || doneIds.length) {
      await updateDayItemLog(null, m, currentTime, 'partial');
    }
    return m;
  }
  return model;
};

export const updateDayModel = async (
  model: DayModel,
  currentTime: number,
  withSession = false
  // schedule?: ScheduleInterval[]
) => {
  if (model) {
    let m = withSession
      ? runDayModel(model, currentTime, getExactTime())
      : model;
    if (m) {
      m = await updateDayLogs(m, currentTime);
      await store.dispatch(storeDay(m));
      return m;
    }
  }
  return model;
};

export const updateDayTasks = async (model: DayModel, tasks: Task[]) => {
  await store.dispatch(storeDay({ ...model, tasks }));
};

export const handleDayTaskAction = async (
  model: DayModel,
  action: ModelAction<Task>,
  currentTime: number
  // schedule?: ScheduleInterval[]
) => {
  logAppEvent('task_' + action.type, {
    desc: action.model.content,
    category: 'task',
  });
  if (action.type === 'remove' || action.type === 'delete') {
    const tasks = model.tasks.filter(
      (t) => getDayItemKey(t) !== getDayItemKey(action.model)
    );
    const remains = model.tasks?.some((t) => t.id !== action.model.id);
    const plan =
      (remains
        ? model.plan
        : model.plan?.filter((i) => i.id !== action.model.id)) ?? [];
    await updateDayModel(
      { ...model, plan, tasks },
      currentTime,
      false
      // schedule
    );
  }
  if (action.type === 'create') {
    const isQuick = action.args === 'quick';
    const task = await initTask(action.model, model.tasks, !isQuick);
    task.clone = true;
    const tasks = isQuick ? [task, ...model.tasks] : [...model.tasks, task];
    await updateDayModel({ ...model, tasks }, currentTime, false);
  }
  if (action.type === 'update') {
    if (model.tasks) {
      let tasks = await updateTaskPartial(
        action.model,
        model.tasks,
        action.partial,
        action.sync,
        model.date
      );
      if (action.partial?.progress) {
        tasks = tasks.map((task) => {
          const t = { ...task };
          if (
            t.id === action.model.id &&
            t.split !== action.model.split &&
            !t.completed
          ) {
            return { ...task, progress: action.partial?.progress };
          }
          return task;
        });
      }
      const m = await updateDayModel({ ...model, tasks }, currentTime, false);
      if (action.partial?.progress) {
        await updateDayItemLog(action.model.id, m, currentTime);
      }
    }
  }
  if (action.type === 'split') {
    const index = model.tasks.findIndex(
      (t) => getDayItemKey(t) === getDayItemKey(action.model)
    );
    const split = Math.max(
      ...model.tasks
        .filter((t) => t.id === action.model.id)
        .map((t) => (t.split ?? 1) + 1)
    );
    const minutes =
      action.model.minutes !== undefined
        ? Math.max(
            rulebook.task.smDuration,
            normalizeTime(action.model.minutes / 2)
          )
        : rulebook.task.defaultDuration;
    const tasks = [...model.tasks];
    tasks[index] = { ...action.model, minutes };
    tasks.splice(index + 1, 0, {
      ...action.model,
      split,
      minutes,
      completed: false,
    });
    await updateDayModel({ ...model, tasks }, currentTime, false);
  }
};

export const handleTaskDbAction = async (
  model: DayModel,
  action: ModelAction<Task>,
  currentTime: number
  // schedule?: ScheduleInterval[]
) => {
  if (action.type === 'update') {
    await updateTaskPartials([
      { taskId: action.model.id, data: action.partial },
    ]);
    if (action.sync) {
      const tasks = [...model.tasks];
      tasks
        .filter((t) => t.id === action.model.id)
        .forEach(async (task) => {
          const index = tasks.findIndex(
            (t) => getDayItemKey(t) === getDayItemKey(task)
          );
          if (model.date < getDateKey()) {
            const { progress, ...p } = action.partial;
            action.partial = p;
          }
          tasks[index] = { ...task, ...action.partial };
        });
      await updateDayModel({ ...model, tasks }, currentTime, false);
    }
  }
};

export const syncTasks = (
  activeTasks: Record<string, Task>,
  taskList: Task[]
) => {
  const list = taskList ? [...taskList] : [];
  list.forEach((tt, i) => {
    const task = activeTasks[tt.id];
    if (task) {
      const t = {
        ...tt,
        content: task.content,
        contentHtml: task.contentHtml,
        contentShort: task.contentShort,
      };
      if (!t.localBrief) {
        if (task.brief) {
          t.brief = task.brief;
          t.briefHtml = task.briefHtml;
        }
      }
      if (task.progress) {
        t.progress = { ...task.progress };
      }
      if (task.boost) {
        t.boost = task.boost;
      }
      list[i] = t;
    }
  });
  return list;
};

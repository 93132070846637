const rulebook = {
  session: {
    minBlockDuration: 1,
    forcePlannedTask: true,
    breakDings: 3,
  },
  task: {
    defaultDuration: 30,
    smDuration: 0,
    defaultBreak: 5,
  },
};

export default rulebook;

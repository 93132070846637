import React from 'react';
//import NProgress from 'nprogress';
import { Box, LinearProgress } from '@mui/material';

const LoadingScreen: React.FC = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
      }}
    >
      <Box sx={{ width: '90%' }}>
        <LinearProgress sx={{ height: 8, borderRadius: 5 }} />
      </Box>
    </Box>
  );
};

export default LoadingScreen;

import { ModelAction } from 'models/model-types';
import { Task } from 'models/task';
import { updateTaskPartial } from 'features/tasks/task-func';
import rulebook from 'features/rulebook';
import {
  DayModel,
  DayBlockState,
  getDaySeconds,
  getDayItemKey,
  MINUTES_INC,
  getDayItem,
} from './day-types';
import {
  isTodayModel,
  insertPlanInterval,
  insertDayInterval,
  getExactTime,
} from './day-layer';
import { logAppEvent } from 'api/analytics';

export const applyTaskAction = async (
  dayModel: DayModel,
  action: ModelAction<Task>,
  currentTime: number
) => {
  let m: DayModel = { ...dayModel };
  logAppEvent('task_' + action.type, {
    desc: action.model?.content,
    category: 'task',
  });
  if (action.type === 'start') {
    m = insertCurrentSession(m, currentTime);
    const state = action.args as DayBlockState;
    if (action.model.id && state) {
      const continues =
        dayModel.session?.item &&
        getDayItemKey(action.model) === getDayItemKey(dayModel.session.item) &&
        dayModel.session.item.state !== 'stop';

      m.session = {
        ...m.session,
        item: getDayItem(action.model, state),
        started: currentTime,
        pausedSeconds: state === 'break' ? getDaySeconds() : null,
        auto: false,
      };
      if (state === 'on' && !continues) {
        m.session.startedSeconds = getDaySeconds();
      }
      if (state === 'stop') {
        m.session.startedSeconds = null;
      }
      const forced =
        dayModel.session?.auto &&
        getDayItemKey(action.model) !== getDayItemKey(dayModel.session.item);
      if (forced) {
        m.session.forced = true;
      }
    } else {
      m.session = null;
    }
  }

  if (action.type === 'complete') {
    const completed = !action.model.completed;
    m.tasks = await updateTaskPartial(action.model, dayModel.tasks, {
      completed,
    });
    if (completed) {
      const time =
        getExactTime() >= currentTime + rulebook.session.minBlockDuration
          ? currentTime + MINUTES_INC
          : currentTime;
      m.plan
        .filter(
          (i) =>
            i.end > currentTime &&
            getDayItemKey(i) === getDayItemKey(action.model)
        )
        .forEach((i) => {
          m.plan = insertPlanInterval(
            m.plan,
            { state: 'on' },
            Math.max(i.start, time),
            i.end
          );
        });
    }
    if (
      dayModel.session?.item &&
      getDayItemKey(action.model) === getDayItemKey(dayModel.session.item) &&
      completed
    ) {
      m.session = null;
    }
  }
  return initSession(m, currentTime);
};

export const getRunningTask = (model: DayModel) => {
  if (model?.tasks && model.session?.item?.id) {
    return model.tasks.find(
      (t) => getDayItemKey(t) === getDayItemKey(model.session.item)
    );
  }
  return null;
};

export const runSession = async (
  model: DayModel,
  currentTime: number
): Promise<DayModel> => {
  const time = model?.session?.started ? model.session.started : currentTime;
  const p = model?.plan?.filter(
    (i) => i.start >= time && i.start <= currentTime && i.id
  );
  const current = p?.length ? p[p.length - 1] : null;
  if (
    current &&
    current.id === model?.session?.item?.id &&
    model?.session?.item?.state === 'stop'
  ) {
    return model;
  }
  const autoStop =
    model?.session?.auto && (!current?.id || current.end <= currentTime);
  const forced = model?.session?.forced && model?.session?.item?.state === 'on';
  if (
    (!forced &&
      current?.id &&
      getDayItemKey(current) !== getDayItemKey(model.session?.item)) ||
    autoStop
  ) {
    const task = current?.id
      ? model.tasks.find((t) => getDayItemKey(t) === getDayItemKey(current))
      : model.tasks.find(
          (t) => getDayItemKey(t) === getDayItemKey(model.session?.item)
        );
    if (task) {
      const m = await applyTaskAction(
        model,
        {
          type: 'start',
          args: autoStop ? 'stop' : 'on',
          model: task,
        },
        currentTime
      );
      m.session.auto = true;
      return m;
    }
  }
  return model;
};

export const initSession = async (model: DayModel, currentTime: number) => {
  if (
    isTodayModel(model) &&
    !model.session?.item?.id &&
    model.tasks?.length > 0
  ) {
    const task = model.tasks.find((t) => !t.completed);
    if (task) {
      return applyTaskAction(
        model,
        {
          type: 'start',
          args: 'stop',
          model: task,
        },
        currentTime
      );
    }
  }
  return model;
};
const insertCurrentSession = (model: DayModel, currentTime: number) => {
  if (
    model.session?.item &&
    model.session.item.state === 'on' &&
    getExactTime() - currentTime > rulebook.session.minBlockDuration
  ) {
    return insertDayInterval(
      model,
      model.session?.item,
      currentTime,
      currentTime + MINUTES_INC
    );
  }
  return model;
};

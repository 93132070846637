import UserContext from 'features/user/user-context';
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';

export const fsCollection = (name: string) => {
  const uid = UserContext.uid();
  const db = getFirestore();
  return collection(db, 'users', uid, name);
};

// export const fsDoc = (name: string, id: string) => {
//   return doc(fsCollection(name), id);
// };

export const fsSetDoc = (name: string, id: string, item: any) => {
  return setDoc(doc(fsCollection(name), id), item);
};

export const fsUpdateDoc = (name: string, id: string, partial: any) => {
  return updateDoc(doc(fsCollection(name), id), partial);
};

export const fsGetDoc = (name: string, id: string) => {
  return getDoc(doc(fsCollection(name), id));
};

export const fsDeleteDoc = (name: string, id: string) => {
  return deleteDoc(doc(fsCollection(name), id));
};

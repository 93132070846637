import React from 'react';

import { Box } from '@mui/material';
import { markdownToHtml } from 'utils/markdown';

// const PREFIX = 'Markdown';

// const classes = {
//   markdown: `${PREFIX}-markdown`,
// };

// // TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
// const Root = styled('div')(({ theme }) => ({
//   [`& .${classes.markdown}`]: {
//     '& ul,ol': {
//       paddingInlineStart: '15px',
//     },
//     '& code': {
//       padding: '.2em .4em',
//       backgroundColor: theme.palette.background.dark,
//       whiteSpace: 'pre-wrap',
//       borderRadius: '3px',
//       fontSize: '125%',
//     },
//   },
// }));

type MarkdownProps = {
  text: string;
  html?: string;
};

export const Markdown: React.FC<MarkdownProps> = ({
  text,
  html,
}: MarkdownProps) => {
  const content = html ?? markdownToHtml(text);

  return (
    <Box
      sx={{
        '& ul,ol': {
          paddingInlineStart: '15px',
        },
        '& code': {
          padding: '.2em .4em',
          backgroundColor: 'background.dark',
          whiteSpace: 'pre-wrap',
          borderRadius: '3px',
          //fontSize: '125%',
        },
      }}
    >
      {text && (
        <div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      )}
    </Box>
  );
};

export default Markdown;

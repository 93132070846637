import React from 'react';
import type { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import TopBar from './TopBar';

// const PREFIX = 'MainLayout';

// const classes = {
//   root: `${PREFIX}-root`,
//   wrapper: `${PREFIX}-wrapper`,
//   contentContainer: `${PREFIX}-contentContainer`,
//   content: `${PREFIX}-content`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     backgroundColor: theme.palette.background.default,
//     display: 'flex',
//     height: '100%',
//     overflow: 'hidden',
//     width: '100%',
//   },

//   [`& .${classes.wrapper}`]: {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden',
//     paddingTop: 64,
//   },

//   [`& .${classes.contentContainer}`]: {
//     display: 'flex',
//     flex: '1 1 auto',
//     overflow: 'hidden',
//   },

//   [`& .${classes.content}`]: {
//     flex: '1 1 auto',
//     height: '100%',
//     overflow: 'auto',
//   },
// }));

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }: MainLayoutProps) => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    }}
  >
    <TopBar />
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  </Box>
);

export default MainLayout;

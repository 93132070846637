const batchSize = 10;
const loadBatch = async <T>(
  ids: string[],
  loader: (batchIds: string[]) => Promise<T[]>
): Promise<T[]> => {
  const n = Math.floor((ids.length - 1) / batchSize);
  const tasks = [];
  for (let i = 0; i <= n; i += 1) {
    const batchIds = ids.slice(i * batchSize, (i + 1) * batchSize);
    tasks.push(loader(batchIds));
  }
  const results = await Promise.all(tasks);
  return results.flatMap((r) => r);
};

export default loadBatch;

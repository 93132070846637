import UserContext from 'features/user/user-context';
import { getAnalytics, logEvent } from 'firebase/analytics';
import * as Sentry from '@sentry/react';

export const logAppEvent = (name: string, params?: any) => {
  if (UserContext.isTest) return;
  const analytics = getAnalytics();
  const email = params?.email ?? UserContext.getUser()?.email;
  const p = { ...params, email };
  logEvent(analytics, name, p);

  Sentry.addBreadcrumb({
    category: params?.category,
    message: name + (params?.desc ? ' ' + params.desc : ''),
    level: Sentry.Severity.Info,
  });
};

import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
} from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import firebaseApp from 'lib/firebase-app';
import { AppUser } from 'models/user';
import UserContext from '../features/user/user-context';
import { logAppEvent } from './analytics';

export const resetPassword = async (email: string) => {
  try {
    const auth = getAuth(firebaseApp);
    await sendPasswordResetEmail(auth, email, { url: window.location.origin });
  } catch (e) {
    console.error('resetPassword', e);
    throw e;
  }
};

export const updateUserPassword = async (newPassword: string) => {
  if (UserContext.isImpersonated()) return;
  try {
    const user = getAuth(firebaseApp).currentUser;
    await updatePassword(user, newPassword);
  } catch (e) {
    console.error('updatePassword', e);
    throw e;
  }
};

export const updateUserProfile = async (email, name: string) => {
  if (UserContext.isImpersonated()) return;
  try {
    const user = getAuth(firebaseApp).currentUser;
    if (user.displayName !== name) {
      await updateProfile(user, { displayName: name });
    }
    if (user.email !== email) {
      await updateEmail(user, email);
    }

    const update = {
      email,
      name,
    };
    const db = getFirestore();
    updateDoc(doc(db, 'users', UserContext.uid()), update);
    UserContext.updateUser(update);
  } catch (e) {
    console.error('updatePassword', e);
    throw e;
  }
};

export const signInUserWithEmailAndPassword = (
  email: string,
  password: string
): Promise<any> => {
  const auth = getAuth(firebaseApp);
  return signInWithEmailAndPassword(auth, email, password);
};

export const signInWithGoogle = (): Promise<any> => {
  const auth = getAuth(firebaseApp);
  return signInWithRedirect(auth, new GoogleAuthProvider());
};

export const signUpWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  const auth = getAuth(firebaseApp);
  const credential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  await updateProfile(credential.user, { displayName: name });
  const appUser = await createAppUser(credential.user, name);
  UserContext.setUser(appUser, false);
};

export const logout = (): Promise<void> => {
  UserContext.removeStoredUid();
  return getAuth(firebaseApp).signOut();
};

export const currentUser = () => getAuth(firebaseApp).currentUser;

const createAppUser = async (u: User, name?: string): Promise<AppUser> => {
  const db = getFirestore();
  const appUser = {
    uid: u.uid,
    name: name ?? u.displayName,
    email: u.email,
    avatar: u.photoURL,
    plan: 'Free',
  };
  await setDoc(doc(db, 'users', u.uid), appUser);
  logAppEvent('sign_up', { email: appUser.email, category: 'auth' });
  return appUser;
};

export const loadAppUser = async (): Promise<AppUser | null> => {
  try {
    let uid = UserContext.getStoredUid();

    const u = currentUser();
    if (!uid) {
      uid = u?.uid;
    }

    if (!uid) {
      UserContext.setUser(null, false);
      return null;
    }

    const db = getFirestore();
    const d = doc(db, 'users', uid);

    const userDoc = await getDoc(d);

    let appUser = userDoc.exists() ? (userDoc.data() as AppUser) : null;

    if (appUser && u && !appUser.admin && appUser.uid !== u.uid) {
      UserContext.setUser(null, false);
      return null;
    }

    if (!appUser && u) {
      appUser = await createAppUser(u);
    }
    UserContext.setUser(appUser, false);
    logAppEvent('user_auth', { category: 'auth' });
    return appUser;
  } catch (e) {
    console.error('loadAppUser', e);
    throw e;
  }
};

export const loginByAdmin = async (email: string): Promise<AppUser | null> => {
  if (UserContext.isImpersonated() || !UserContext.getUser()?.admin) {
    return null;
  }
  try {
    const db = getFirestore();
    const q = query(collection(db, 'users'), where('email', '==', email));
    const data = await getDocs(q);
    if (data.empty) return null;

    const appUser = data.docs[0].data() as AppUser;
    UserContext.setUser(appUser, true);
    return appUser;
  } catch (e) {
    console.error('loginByAdmin', e);
    throw e;
  }
};

import React from 'react';
import type { FC } from 'react';

import Page from 'components/ui/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';

const HomeView: FC = () => (
  <Page title="Make Things Happen">
    <Hero />
    <Features />
    <Testimonials />
    <CTA />
    <FAQS />
  </Page>
);

export default HomeView;
